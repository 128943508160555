import React, { useCallback } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TextField, Box } from "@mui/material";
import { BaseButton } from "../../styled/BaseStyles";
import { DeleteButton } from "../../styled/Button";
import { DeleteForeverIcon, DragIndicatorIcon } from "./Icons";

const SortableItem = ({
  id,
  item,
  onFieldNameChange,
  onValueChange,
  onRemove,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 2,
        gap: 1,
      }}
    >
      <BaseButton {...attributes} {...listeners}>
        <DragIndicatorIcon />
      </BaseButton>
      <TextField
        label="Field Name"
        value={item.fieldName}
        onChange={(e) => onFieldNameChange(id, e.target.value)}
        size="small"
        sx={{ mr: 1 }}
      />
      <TextField
        label="Value"
        value={item.fieldValue}
        onChange={(e) => onValueChange(id, e.target.value)}
        fullWidth
        size="small"
      />
      <DeleteButton onClick={() => onRemove(id)}>
        <DeleteForeverIcon />
      </DeleteButton>
    </Box>
  );
};

const ExtraInfoList = ({
  extraInfoItems,
  setExtraInfoItems,
  setFormValues,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        setExtraInfoItems((items) => {
          const oldIndex = Object.keys(items).indexOf(active.id);
          const newIndex = Object.keys(items).indexOf(over.id);

          const newOrder = arrayMove(Object.entries(items), oldIndex, newIndex);
          const newItems = Object.fromEntries(
            newOrder.map(([key, value], index) => [
              (index + 1).toString(),
              value,
            ])
          );

          setFormValues((prev) => ({ ...prev, extraInfo: newItems }));
          return newItems;
        });
      }
    },
    [setExtraInfoItems, setFormValues]
  );

  const handleFieldNameChange = useCallback(
    (key, newFieldName) => {
      setExtraInfoItems((prevItems) => {
        const updatedItems = {
          ...prevItems,
          [key]: { ...prevItems[key], fieldName: newFieldName },
        };
        setFormValues((prev) => ({ ...prev, extraInfo: updatedItems }));
        return updatedItems;
      });
    },
    [setFormValues, setExtraInfoItems]
  );

  const handleValueChange = useCallback(
    (key, newValue) => {
      setExtraInfoItems((prevItems) => {
        const updatedItems = {
          ...prevItems,
          [key]: { ...prevItems[key], fieldValue: newValue },
        };
        setFormValues((prev) => ({ ...prev, extraInfo: updatedItems }));
        return updatedItems;
      });
    },
    [setFormValues, setExtraInfoItems]
  );

  const handleRemove = useCallback(
    (keyToRemove) => {
      setExtraInfoItems((prevItems) => {
        const updatedItems = Object.entries(prevItems)
          .filter(([key]) => key !== keyToRemove)
          .reduce((acc, [_, value], index) => {
            acc[(index + 1).toString()] = value;
            return acc;
          }, {});

        setFormValues((prev) => ({ ...prev, extraInfo: updatedItems }));
        return updatedItems;
      });
    },
    [setFormValues, setExtraInfoItems]
  );

  const handleAddExtraInfo = useCallback(() => {
    setExtraInfoItems((prevItems) => {
      prevItems = prevItems || {};
      const length = Object.keys(prevItems).length + 1;
      const newKey = length.toString();
      const updatedItems = {
        ...prevItems,
        [newKey]: { fieldName: "", fieldValue: "" },
      };
      setFormValues((prev) => ({ ...prev, extraInfo: updatedItems }));
      return updatedItems;
    });
  }, [setFormValues, setExtraInfoItems]);

  return (
    <>
      <h3>Extra Info</h3>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        {extraInfoItems && (
          <SortableContext
            items={Object.keys(extraInfoItems)}
            strategy={verticalListSortingStrategy}
          >
            {extraInfoItems &&
              Object.entries(extraInfoItems).map(([key, item]) => (
                <SortableItem
                  key={key}
                  id={key}
                  item={item}
                  onFieldNameChange={handleFieldNameChange}
                  onValueChange={handleValueChange}
                  onRemove={handleRemove}
                />
              ))}
          </SortableContext>
        )}
        <BaseButton onClick={handleAddExtraInfo}>Add Extra Info</BaseButton>
      </DndContext>
    </>
  );
};

export default ExtraInfoList;
