// pages/Login.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Box, TextField, Typography, Button } from "@mui/material";
import { GlobalImageURLs } from "../components/constant/Global";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginButton } from "../styled/Button";
import AuthThunks from "../redux/thunks/authThunks";

const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;

const FormContainer = styled(Box)`
  width: 100%;
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #ffffff;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 300px;
    border-radius: 0;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 200px;
`;

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [countdown, setCountdown] = useState(0);

  const navigate = useNavigate();

  // const config = {
  //   clienId: process.env.REACT_APP_CLIENT_ID,
  //   oauthURL: `${process.env.REACT_APP_OAUTH_API}/oauth/authorize`,
  //   redirectUrl: process.env.REACT_APP_CALLBACK_URL,
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    alert("Password reset link sent to your email");
    setCountdown(60);
    dispatch(AuthThunks.forgotPassword(email));
  };

  const handleBackToLoginPage = () => {
    navigate("/login");
  };

  // const handleLoginWithYoov = () => {
  //   const oauthURL = `${config.oauthURL}?client_id=${config.clienId}&redirect_uri=${config.redirectUrl}&response_type=code`;
  //   window.location.href = oauthURL;
  // };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  return (
    <LoginContainer>
      <LogoWrapper>
        <Logo src={GlobalImageURLs.YoovLogoB} alt="YOOV Logo" />
        <Typography variant="h6">Reset Your Password</Typography>
      </LogoWrapper>
      <FormContainer mt={2}>
        <Typography variant="h6" fontWeight={"bold"} fontSize={"1rem"}>
          Enter your user account's verified email address and we will send you
          a password reset link.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <LoginButton type="submit" disabled={countdown > 0}>
            {countdown > 0 ? `Send (${countdown})` : "Send"}
          </LoginButton>
        </form>
        <Button onClick={handleBackToLoginPage}>Back to Login Page</Button>
      </FormContainer>
    </LoginContainer>
  );
};

export default ForgotPassword;
