import axios from "axios";
import AuthActions from "../actions/authActions";
import { getApiUrl } from "../../utils/baseApiUrl";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { errorMessages } from "../../error/errorMessages";

const authApiURL = getApiUrl("/api/v1/auth");

const userLogin = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApiURL}/login`, data);
    const { accessToken, refreshToken } = response.data;
    saveTokens({ accessToken, refreshToken });
    dispatch(AuthActions.loginSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      AuthActions.loginFailure(errorMessage || errorMessages.invalidCredentials)
    );
  }
};

// const handleOauthCallback = (data) => async (dispatch) => {
//   try {
//     const response = await axios.post(`${authApiURL}/oauth`, data);
//     const { accessToken, refreshToken } = response.data;
//     saveTokens({ accessToken, refreshToken });
//     dispatch(AuthActions.loginSuccess({ accessToken, refreshToken }));
//   } catch (error) {
//     const errorMessage = getErrorMessage(error);
//     dispatch(
//       AuthActions.loginFailure(errorMessage || errorMessages.oauthLoginFailed)
//     );
//   }
// };

const userRegister = (data) => async (dispatch) => {
  try {
    await axios.post(`${authApiURL}/register`, data);
    dispatch(AuthActions.registerSuccess());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      AuthActions.registerFailure(
        errorMessage || errorMessages.user.registerFailed
      )
    );
  }
};

const userLogout = () => (dispatch) => {
  removeTokens();
  dispatch(AuthActions.logout());
};

const saveTokens = (tokens) => {
  localStorage.setItem("authToken", tokens.accessToken);
  localStorage.setItem("refreshToken", tokens.refreshToken);
};

const removeTokens = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
};

const forgotPassword = (email) => async (dispatch) => {
  try {
    await axios.post(`${authApiURL}/password/forgot`, { email });
    dispatch(AuthActions.forgotPasswordSuccess());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      AuthActions.forgotPasswordFailure(
        errorMessage || errorMessages.user.forgotPasswordFailed
      )
    );
  }
};

const resetPassword = (data) => async (dispatch) => {
  try {
    await axios.post(`${authApiURL}/password/reset`, data);
    dispatch(AuthActions.resetPasswordSuccess());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      AuthActions.resetPasswordFailure(
        errorMessage || errorMessages.user.resetPasswordFailed
      )
    );
  }
};

const getResetEmail = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${authApiURL}/password/${token}`);
    dispatch(AuthActions.getResetEmailSuccess(response.data.email));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      AuthActions.getResetEmailFailure(
        errorMessage || errorMessages.user.getResetEmailFailed
      )
    );
  }
};

const AuthThunks = {
  userLogin,
  // handleOauthCallback,
  userRegister,
  userLogout,
  forgotPassword,
  resetPassword,
  getResetEmail,
};

export default AuthThunks;
