import {
  GET_NAME_CARD_COLLECTIONS_SUCCESS,
  GET_NAME_CARD_COLLECTIONS_FAILURE,
  ADD_NAME_CARD_COLLECTION_SUCCESS,
  ADD_NAME_CARD_COLLECTION_FAILURE,
  DELETE_NAME_CARD_COLLECTION_SUCCESS,
  DELETE_NAME_CARD_COLLECTION_FAILURE,
  UPDATE_NAME_CARD_COLLECTION_SUCCESS,
  UPDATE_NAME_CARD_COLLECTION_FAILURE,
  IS_ALREADY_IN_COLLECTION,
  RESET_ADD_COLLECTION_STATUS,
  RESET_UPDATE_COLLECTION_STATUS,
  RESET_DELETE_COLLECTION_STATUS,
} from "../../actions/nameCard/collectionActions";

const initialState = {
  all: [],
  error: null,
  isAlreadyInCollection: false,
  addCollectionStatus: null,
  updateCollectionStatus: null,
  deleteCollectionStatus: null,
};

const nameCardCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NAME_CARD_COLLECTIONS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        error: null,
      };
    case GET_NAME_CARD_COLLECTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_NAME_CARD_COLLECTION_SUCCESS:
      return {
        ...state,
        addCollectionStatus: "success",
        error: null,
      };
    case ADD_NAME_CARD_COLLECTION_FAILURE:
      return {
        ...state,
        addCollectionStatus: "failure",
        error: action.payload,
      };
    case DELETE_NAME_CARD_COLLECTION_SUCCESS:
      return {
        ...state,
        deleteCollectionStatus: "success",
        error: null,
      };
    case DELETE_NAME_CARD_COLLECTION_FAILURE:
      return {
        ...state,
        deleteCollectionStatus: "failure",
        error: action.payload,
      };
    case UPDATE_NAME_CARD_COLLECTION_SUCCESS:
      return {
        ...state,
        updateCollectionStatus: "success",
        error: null,
      };
    case UPDATE_NAME_CARD_COLLECTION_FAILURE:
      return {
        ...state,
        updateCollectionStatus: "failure",
        error: action.payload,
      };
    case IS_ALREADY_IN_COLLECTION:
      return {
        ...state,
        isAlreadyInCollection: action.payload,
      };
    case RESET_ADD_COLLECTION_STATUS:
      return {
        ...state,
        addCollectionStatus: null,
      };
    case RESET_DELETE_COLLECTION_STATUS:
      return {
        ...state,
        deleteCollectionStatus: null,
      };
    case RESET_UPDATE_COLLECTION_STATUS:
      return {
        ...state,
        updateCollectionStatus: null,
      };
    default:
      return state;
  }
};

export default nameCardCollectionReducer;
