export const GET_TARGET_NAME_CARD_STYLE_CONFIG_SUCCESS =
  "GET_TARGET_NAME_CARD_STYLE_CONFIG_SUCCESS";
export const GET_TARGET_NAME_CARD_STYLE_CONFIG_FAILURE =
  "GET_TARGET_NAME_CARD_STYLE_CONFIG_FAILURE";
export const GET_MY_NAME_CARD_STYLE_CONFIGS_SUCCESS =
  "GET_MY_NAME_CARD_STYLE_CONFIGS_SUCCESS";
export const GET_MY_NAME_CARD_STYLE_CONFIGS_FAILURE =
  "GET_MY_NAME_CARD_STYLE_CONFIGS_FAILURE";
export const CREATE_NAME_CARD_STYLE_CONFIG_SUCCESS =
  "CREATE_NAME_CARD_STYLE_CONFIG_SUCCESS";
export const CREATE_NAME_CARD_STYLE_CONFIG_FAILURE =
  "CREATE_NAME_CARD_STYLE_CONFIG_FAILURE";
export const DELETE_NAME_CARD_STYLE_CONFIG_SUCCESS =
  "DELETE_NAME_CARD_STYLE_CONFIG_SUCCESS";
export const DELETE_NAME_CARD_STYLE_CONFIG_FAILURE =
  "DELETE_NAME_CARD_STYLE_CONFIG_FAILURE";
export const UPDATE_NAME_CARD_STYLE_CONFIG_SUCCESS =
  "UPDATE_NAME_CARD_STYLE_CONFIG_SUCCESS";
export const UPDATE_NAME_CARD_STYLE_CONFIG_FAILURE =
  "UPDATE_NAME_CARD_STYLE_CONFIG_FAILURE";
export const SET_GLOBAL_NAME_CARD_STYLE_CONFIG_SUCCESS =
  "SET_GLOBAL_NAME_CARD_STYLE_CONFIG_SUCCESS";
export const SET_GLOBAL_NAME_CARD_STYLE_CONFIG_FAILURE =
  "SET_GLOBAL_NAME_CARD_STYLE_CONFIG_FAILURE";
export const RESET_CREATE_STYLE_CONFIG_STATUS =
  "RESET_CREATE_STYLE_CONFIG_STATUS";
export const RESET_UPDATE_STYLE_CONFIG_STATUS =
  "RESET_UPDATE_STYLE_CONFIG_STATUS";
export const RESET_DELETE_STYLE_CONFIG_STATUS =
  "RESET_DELETE_STYLE_CONFIG_STATUS";
export const RESET_SET_GLOBAL_STYLE_CONFIG_STATUS =
  "RESET_SET_GLOBAL_STYLE_CONFIG_STATUS";

const getTargetSuccess = (data) => ({
  type: GET_TARGET_NAME_CARD_STYLE_CONFIG_SUCCESS,
  payload: data,
});

const getTargetFailure = (error) => ({
  type: GET_TARGET_NAME_CARD_STYLE_CONFIG_FAILURE,
  payload: error,
});

const getAllSuccess = (data) => ({
  type: GET_MY_NAME_CARD_STYLE_CONFIGS_SUCCESS,
  payload: data,
});

const getAllFailure = (error) => ({
  type: GET_MY_NAME_CARD_STYLE_CONFIGS_FAILURE,
  payload: error,
});

const createTargetSuccess = (data) => ({
  type: CREATE_NAME_CARD_STYLE_CONFIG_SUCCESS,
  payload: data,
});

const createTargetFailure = (error) => ({
  type: CREATE_NAME_CARD_STYLE_CONFIG_FAILURE,
  payload: error,
});

const deleteTargetSuccess = (data) => ({
  type: DELETE_NAME_CARD_STYLE_CONFIG_SUCCESS,
  payload: data,
});

const deleteTargetFailure = (error) => ({
  type: DELETE_NAME_CARD_STYLE_CONFIG_FAILURE,
  payload: error,
});

const updateTargetSuccess = (data) => ({
  type: UPDATE_NAME_CARD_STYLE_CONFIG_SUCCESS,
  payload: data,
});

const updateTargetFailure = (error) => ({
  type: UPDATE_NAME_CARD_STYLE_CONFIG_FAILURE,
  payload: error,
});

const setTargetGlobalSuccess = (data) => ({
  type: SET_GLOBAL_NAME_CARD_STYLE_CONFIG_SUCCESS,
  payload: data,
});

const setTargetGlobalFailure = (error) => ({
  type: SET_GLOBAL_NAME_CARD_STYLE_CONFIG_FAILURE,
  payload: error,
});

const resetCreateStyleConfigStatus = () => ({
  type: RESET_CREATE_STYLE_CONFIG_STATUS,
});

const resetUpdateStyleConfigStatus = () => ({
  type: RESET_UPDATE_STYLE_CONFIG_STATUS,
});

const resetDeleteStyleConfigStatus = () => ({
  type: RESET_DELETE_STYLE_CONFIG_STATUS,
});

const resetSetGlobalStyleConfigStatus = () => ({
  type: RESET_SET_GLOBAL_STYLE_CONFIG_STATUS,
});

const NameCardStyleConfigActions = {
  getTargetSuccess,
  getTargetFailure,
  getAllSuccess,
  getAllFailure,
  createTargetSuccess,
  createTargetFailure,
  deleteTargetSuccess,
  deleteTargetFailure,
  updateTargetSuccess,
  updateTargetFailure,
  setTargetGlobalSuccess,
  setTargetGlobalFailure,
  resetCreateStyleConfigStatus,
  resetUpdateStyleConfigStatus,
  resetDeleteStyleConfigStatus,
  resetSetGlobalStyleConfigStatus,
};

export default NameCardStyleConfigActions;
