// pages/Login.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "../components/constant/Icons";
import { GlobalImageURLs } from "../components/constant/Global";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthThunks from "../redux/thunks/authThunks";
import { LoginButton } from "../styled/Button";
import { StyledTabs, StyledTab } from "../styled/BaseStyles";
import NotiDialog from "../components/dialogs/NotiDialog";
import {
  NotiType,
  NotiName,
  NotiAction,
  getNotiInformation,
} from "../components/constant/Notification";

const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;

const FormContainer = styled(Box)`
  width: 100%;
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #ffffff;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 300px;
    border-radius: 0;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 200px;
`;

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isError = useSelector((state) => state.auth.error);
  const [isLogin, setIsLogin] = useState(false);
  const [notiInfo, setNotiInfo] = useState({});

  // const config = {
  //   clienId: process.env.REACT_APP_CLIENT_ID,
  //   oauthURL: `${process.env.REACT_APP_OAUTH_API}/oauth/authorize`,
  //   redirectUrl: process.env.REACT_APP_CALLBACK_URL,
  // };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(AuthThunks.userLogin({ email, password }));
    setIsLogin(true);
    setNotiInfo(
      getNotiInformation(NotiAction.Login, NotiName.User, NotiType.Error)
    );
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleCloseDialog = () => {
    setIsLogin(false);
  };

  // const handleLoginWithYoov = () => {
  //   const oauthURL = `${config.oauthURL}?client_id=${config.clienId}&redirect_uri=${config.redirectUrl}&response_type=code`;
  //   window.location.href = oauthURL;
  // };

  useEffect(() => {
    if (isAuthenticated && isLogin) {
      navigate("/");
      setIsLogin(false);
    }
  }, [isAuthenticated, navigate, isLogin]);

  return (
    <LoginContainer>
      <LogoWrapper>
        <Logo src={GlobalImageURLs.YoovLogoB} alt="YOOV Logo" />
        <Typography variant="h6">Name Card Admin Panel</Typography>
      </LogoWrapper>
      <FormContainer mt={2}>
        <Typography variant="h5">User Login</Typography>
        <StyledTabs value={tabValue} onChange={handleTabChange} centered>
          <StyledTab label="Account Login" />
        </StyledTabs>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="on"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <LoginButton type="submit">Login</LoginButton>
          {/* <LoginButton onClick={handleLoginWithYoov}>
            Login with YOOV Account
          </LoginButton> */}
        </form>
        <Button onClick={handleForgotPassword}>Forgot Password?</Button>
      </FormContainer>
      {isError && isLogin && (
        <NotiDialog
          openDialog={isLogin}
          onClose={handleCloseDialog}
          notiInfo={notiInfo}
        />
      )}
    </LoginContainer>
  );
};

export default Login;
