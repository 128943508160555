import React from "react";
import styled from "styled-components";
import { Drawer, List, ListItem } from "@mui/material";
import {
  GlobalColor,
  GlobalFontWeight,
  GlobalFontSize,
  GlobalImageURLs,
} from "../constant/Global";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { DrawerWidth, CompnayImage } from "../../styled/BaseStyles";
import { menuItems } from "../constant/Global";

const StyledDrawer = styled(Drawer)`
  min-width: ${DrawerWidth}px;
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: ${DrawerWidth}px;
    background-color: ${GlobalColor.primary};
    color: ${GlobalColor.white};
    font-size: ${GlobalFontSize.medium};
    font-weight: ${GlobalFontWeight.bold};
    border: none;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const DrawerContainer = styled.div`
  width: ${DrawerWidth}px;
  background-color: ${GlobalColor.primary};
  color: ${GlobalColor.white};
  font-size: ${GlobalFontSize.medium};
  font-weight: bold;
`;

const StyledList = styled(List)`
  padding: 0;
  &.MuiList-root {
    margin-top: 24px;
  }
`;

const StyledListItem = styled(ListItem)`
  gap: 1rem;
  &.MuiListItem-root {
    padding: 24px;
    width: 100%;
    border: white;
    background-color: ${GlobalColor.primary};
    &:hover {
      background-color: ${GlobalColor.lightGrey};
      color: ${GlobalColor.primary};
    }
    &.active {
      background-color: ${GlobalColor.lightGrey};
      color: ${GlobalColor.primary};
    }
  }
`;

const HomeDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.user.target);

  return (
    <StyledDrawer variant="permanent">
      <CompnayImage src={GlobalImageURLs.YoovLogoW} />
      <DrawerContainer>
        <StyledList>
          {menuItems.map(
            (item) =>
              (!item.role || (user && user.userRole === item.role)) && (
                <StyledListItem
                  key={item.path}
                  button
                  onClick={() => {
                    navigate(item.path);
                  }}
                  className={location.pathname === item.path ? "active" : ""}
                >
                  {item.icon}
                  {item.label}
                </StyledListItem>
              )
          )}
        </StyledList>
      </DrawerContainer>
    </StyledDrawer>
  );
};

export default HomeDrawer;
