import React, { useState } from "react";
import EditDialog from "../dialogs/EditDialog";
import { GlobalUserTypes } from "../constant/Global";
import { CreateButton } from "../../styled/Button";
import { AddContainer } from "../../styled/BaseStyles";
import { useSelector } from "react-redux";
import { PricePlanOptions } from "../constant/Global";
import NotiDialog from "../dialogs/NotiDialog";
import {
  NotiAction,
  NotiName,
  NotiType,
  getPricePlanInfomation,
} from "../constant/Notification";
import { useUserType } from "../context/UserTypeContext";

const CreateUser = () => {
  const { userType } = useUserType();
  const [openDialog, setOpenDialog] = useState(false);
  const { userLimit, pricePlan } = useSelector((state) => state.auth);

  const userCount = useSelector((state) => state.user.count);
  const nameCardCount = useSelector((state) => state.nameCard.count);
  const [openNotiDialog, setOpenNotiDialog] = useState(false);
  const [notiInfo, setNotiInfo] = useState("");

  const title =
    userType === GlobalUserTypes.user ? "Create User" : "Create Name Card";

  const isDisabled =
    userType === GlobalUserTypes.user
      ? userLimit > userCount + 1
        ? false
        : true
      : PricePlanOptions[pricePlan]?.nameCards > nameCardCount
      ? false
      : true;

  const handleCreateUser = () => {
    if (isDisabled) {
      setOpenNotiDialog(true);
      setNotiInfo(
        userType === GlobalUserTypes.user
          ? getPricePlanInfomation(
              NotiAction.Company,
              NotiName.UserPlan,
              NotiType[pricePlan],
              `Your company already have ${
                userCount + 1
              } user(s)(including you). Upgrade your plan to create more users.`
            )
          : getPricePlanInfomation(
              NotiAction.Company,
              NotiName.NameCardPlan,
              NotiType[pricePlan],
              `You already have ${nameCardCount} name card(s). Upgrade your plan to create more .`
            )
      );
    } else {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <AddContainer>
        <CreateButton onClick={handleCreateUser}>{title}</CreateButton>
      </AddContainer>
      <EditDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        editUser=""
      />
      {openNotiDialog && (
        <NotiDialog
          openDialog={openNotiDialog}
          onClose={() => setOpenNotiDialog(false)}
          notiInfo={notiInfo}
        />
      )}
    </>
  );
};

export default CreateUser;
