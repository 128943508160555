import {
  GET_TARGET_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  GET_TARGET_NAME_CARD_COMPANY_CONFIG_FAILURE,
  GET_ALL_NAME_CARD_COMPANY_CONFIGS_SUCCESS,
  GET_ALL_NAME_CARD_COMPANY_CONFIGS_FAILURE,
  CREATE_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  CREATE_NAME_CARD_COMPANY_CONFIG_FAILURE,
  UPDATE_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  UPDATE_NAME_CARD_COMPANY_CONFIG_FAILURE,
  DELETE_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  DELETE_NAME_CARD_COMPANY_CONFIG_FAILURE,
  UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_SUCCESS,
  UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_FAILURE,
  SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_FAILURE,
  RESET_CREATE_COMPANY_CONFIG_STATUS,
  RESET_DELETE_COMPANY_CONFIG_STATUS,
  RESET_UPDATE_COMPANY_CONFIG_STATUS,
  RESET_SET_GLOBAL_COMPANY_CONFIG_STATUS,
} from "../../actions/nameCard/companyConfigActions";

const initialState = {
  all: [],
  target: {},
  error: null,
  deleteCompanyConfigStatus: null,
  updateCompanyConfigStatus: null,
  createCompanyConfigStatus: null,
  setGlobalCompanyConfigStatus: null,
};

const nameCardCompanyConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_NAME_CARD_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        target: action.payload,
        error: null,
      };
    case GET_TARGET_NAME_CARD_COMPANY_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_NAME_CARD_COMPANY_CONFIGS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        error: null,
      };
    case GET_ALL_NAME_CARD_COMPANY_CONFIGS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_NAME_CARD_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.payload],
        createCompanyConfigStatus: "success",
        error: null,
      };
    case CREATE_NAME_CARD_COMPANY_CONFIG_FAILURE:
      return {
        ...state,
        createCompanyConfigStatus: "failure",
        error: action.payload,
      };
    case UPDATE_NAME_CARD_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        all: state.all.map((config) =>
          config.id === action.payload.id ? action.payload : config
        ),
        updateCompanyConfigStatus: "success",
        error: null,
      };
    case UPDATE_NAME_CARD_COMPANY_CONFIG_FAILURE:
      return {
        ...state,
        updateCompanyConfigStatus: "failure",
        error: action.payload,
      };
    case DELETE_NAME_CARD_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        all: state.all.filter((config) => config.id !== action.payload),
        deleteCompanyConfigStatus: "success",
        error: null,
      };
    case DELETE_NAME_CARD_COMPANY_CONFIG_FAILURE:
      return {
        ...state,
        deleteCompanyConfigStatus: "failure",
        error: action.payload,
      };
    case UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        setGlobalCompanyConfigStatus: "success",
        error: null,
      };
    case SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_FAILURE:
      return {
        ...state,
        setGlobalCompanyConfigStatus: "failure",
        error: action.payload,
      };
    case RESET_CREATE_COMPANY_CONFIG_STATUS:
      return {
        ...state,
        createCompanyConfigStatus: null,
      };
    case RESET_DELETE_COMPANY_CONFIG_STATUS:
      return {
        ...state,
        deleteCompanyConfigStatus: null,
      };
    case RESET_UPDATE_COMPANY_CONFIG_STATUS:
      return {
        ...state,
        updateCompanyConfigStatus: null,
      };
    case RESET_SET_GLOBAL_COMPANY_CONFIG_STATUS:
      return {
        ...state,
        setGlobalCompanyConfigStatus: null,
        error: null,
      };
    default:
      return state;
  }
};

export default nameCardCompanyConfigReducer;
