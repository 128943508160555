export const GET_TARGET_SUCCESS = "GET_TARGET_SUCCESS";
export const GET_TARGET_FAILURE = "GET_TARGET_FAILURE";
export const CHECK_ADMIN_RIGHT = "CHECK_ADMIN_RIGHT";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";
export const CTEATE_USER_SUCCESS = "CTEATE_USER_SUCCESS";
export const CTEATE_USER_FAILURE = "CTEATE_USER_FAILURE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const FIND_LOCAL_USER_BY_EMAIL = "FIND_LOCAL_USER_BY_EMAIL";
export const SHOW_UNAUTHORIZED_DIALOG = "SHOW_UNAUTHORIZED_DIALOG";
export const HIDE_UNAUTHORIZED_DIALOG = "HIDE_UNAUTHORIZED_DIALOG";
export const IMPORT_USERS_SUCCESS = "IMPORT_USERS_SUCCESS";
export const IMPORT_USERS_FAILURE = "IMPORT_USERS_FAILURE";
export const DOWNLOAD_TEMPLATE_FAILURE = "DOWNLOAD_TEMPLATE_FAILURE";
export const RESET_CREATE_STATUS = "RESET_CREATE_STATUS";
export const RESET_UPDATE_STATUS = "RESET_UPDATE_STATUS";
export const RESET_DELETE_STATUS = "RESET_DELETE_STATUS";
export const RESET_IMPORT_USERS_STATUS = "RESET_IMPORT_USERS_STATUS";

const getTargetSuccess = (user) => ({
  type: GET_TARGET_SUCCESS,
  payload: user,
});

const getTargetFailure = (error) => ({
  type: GET_TARGET_FAILURE,
  payload: error,
});

const checkAdminRight = (role) => ({
  type: CHECK_ADMIN_RIGHT,
  payload: role,
});

const getAllSuccess = (users) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users,
});

const getAllFailure = (error) => ({
  type: GET_ALL_USERS_FAILURE,
  payload: error,
});

const createTargetSuccess = (user) => ({
  type: CTEATE_USER_SUCCESS,
  payload: user,
});

const createTargetFailure = (error) => ({
  type: CTEATE_USER_FAILURE,
  payload: error,
});

const updateTargetSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

const updateTargetFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
});

const deleteTargetSuccess = (user) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

const deleteTargetFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  payload: error,
});

const findLocalTargetByEmailSuccess = (email) => ({
  type: FIND_LOCAL_USER_BY_EMAIL,
  payload: email,
});

const showDialogAndLogout = () => ({
  type: SHOW_UNAUTHORIZED_DIALOG,
});

const hideUnauthorizedDialog = () => ({
  type: HIDE_UNAUTHORIZED_DIALOG,
});

const importUsersSuccess = () => ({
  type: IMPORT_USERS_SUCCESS,
});

const importUsersFailure = () => ({
  type: IMPORT_USERS_FAILURE,
});

const downloadTemplateFailure = () => ({
  type: DOWNLOAD_TEMPLATE_FAILURE,
});

const resetCreateUserStatus = () => ({
  type: RESET_CREATE_STATUS,
});

const resetUpdateUserStatus = () => ({
  type: RESET_UPDATE_STATUS,
});

const resetDeleteUserStatus = () => ({
  type: RESET_DELETE_STATUS,
});

const resetImportUsersStatus = () => ({
  type: RESET_IMPORT_USERS_STATUS,
});

const UserActions = {
  getTargetSuccess,
  getTargetFailure,
  checkAdminRight,
  getAllSuccess,
  getAllFailure,
  createTargetSuccess,
  createTargetFailure,
  updateTargetSuccess,
  updateTargetFailure,
  deleteTargetSuccess,
  deleteTargetFailure,
  findLocalTargetByEmailSuccess,
  showDialogAndLogout,
  hideUnauthorizedDialog,
  resetCreateUserStatus,
  resetUpdateUserStatus,
  resetDeleteUserStatus,
  resetImportUsersStatus,
  importUsersSuccess,
  importUsersFailure,
  downloadTemplateFailure,
};

export default UserActions;
