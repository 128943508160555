import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMySelf } from "../redux/thunks/userThunks";
import NameCardUsersInfoTable from "../components/infoTables/NameCardUsersInfoTable";
import PageWrapper from "../components/constant/PageWrapper";
import UserTypeProvider from "../components/context/UserTypeContext";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchMySelf = async () => {
      await dispatch(getMySelf());
    };
    fetchMySelf();
  }, [dispatch]);
  return (
    <PageWrapper>
      <UserTypeProvider>
        <NameCardUsersInfoTable />
      </UserTypeProvider>
    </PageWrapper>
  );
};

export default Home;
