import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import superAdminReducer from "./redux/reducers/superAdminReducers";
import companyReducer from "./redux/reducers/companyReducers";
import authReducer from "../src/redux/reducers/authReducers";
import nameCardReducer from "./redux/reducers/nameCard/userReducers";
import userReducer from "../src/redux/reducers/userReducers";
import nameCardCollection from "./redux/reducers/nameCard/collectionReducers";
import nameCardAnalytics from "./redux/reducers/nameCard/analyticsReducers";
import nameCardStyleConfig from "./redux/reducers/nameCard/styleConfigReducers";
import nameCardCompanyConfig from "./redux/reducers/nameCard/companyConfigReducers";
import authMiddleware from "./middleware/authMiddleware";

const rootReducer = {
  superAdmin: superAdminReducer,
  company: companyReducer,
  auth: authReducer,
  nameCard: nameCardReducer,
  user: userReducer,
  nameCardCollection: nameCardCollection,
  nameCardAnalytics: nameCardAnalytics,
  nameCardStyleConfig: nameCardStyleConfig,
  nameCardCompanyConfig: nameCardCompanyConfig,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk, authMiddleware),
  devTools: composeWithDevTools(),
});

export default store;
