export const errorMessages = {
  useOtherEmail: "Use a different email address.",
  user: {
    notFound: "User not found.",
    exists: "User already exists.",
    notRight: "User is not right.",
    notAuthorized: "User not authorized.",
    loginFailed: "User login failed.",
    registerFailed: "User registration failed.",
    logoutFailed: "User logout failed.",
    missingFields: "User missing fields.",
    forgotPasswordFailed: "User forgot password request failed.",
    resetPasswordFailed: "User password reset failed.",
    resetPasswordTokenNotFound: "User reset password token not found.",
    getSelfFailed: "Failed to get user details.",
    fetchAllFailed: "Failed to fetch users.",
    createFailed: "Failed to create user.",
    updateFailed: "Failed to update user.",
    deleteFailed: "Failed to delete user.",
    fetchCountFailed: "Failed to fetch user count.",
    useOtherEmail: "use a different email address",
    userNotRight: "user is not right",
    notFileUploaded: "no file uploaded",
    fileTypeNotProvide: "file type not provided for",
    AWS: "AWS",
    preSignedUrl: "pre-signed URL",
    invalidCredentials: "invalid credentials",
    nameCardCollectionNotFound: "name card collection not found",
    nameCardAlreadyInCollection: "name card already in collection",
    nameCardStyleConfig: "name card style config",
    uploadFile: "upload file",
    companyNotFound: "company not found",
    existingCompany: "existing company",
    invalidInviteLink: "invalid invite link",
    userNotFound: "user not found",
    userExists: "user exists",
    invalidToken: "invalid token",
    getResetEmailFailed: "failed to get reset email",
  },
  nameCardUser: {
    notFound: "Name card user not found.",
    fetchFailed: "Failed to fetch name card user.",
    fetchAllFailed: "Failed to fetch all name card users.",
    createFailed: "Failed to create name card user.",
    updateFailed: "Failed to update name card user.",
    deleteFailed: "Failed to delete name card user.",
    fetchCountFailed: "Failed to fetch name card user count.",
    uploadFailed: "Failed to upload profile image.",
  },
  nameCardActivity: {
    notFound: "Name card activity not found.",
    fetchFailed: "Failed to fetch name card activity.",
    fetchAllFailed: "Failed to fetch all name card activities.",
  },
  nameCardCollection: {
    notFound: "Name card collection not found.",
    fetchFailed: "Failed to fetch name card collection.",
    fetchAllFailed: "Failed to fetch all name card collections.",
    createFailed: "Failed to create name card collection.",
    updateFailed: "Failed to update name card collection.",
    deleteFailed: "Failed to delete name card collection.",
    alreadyInCollection: "Name card is already in the collection.",
    addCollectionError: "Error adding name card to the collection.",
    validateExistingNameCardCollection:
      "Validation failed for existing name card collection.",
  },
  company: {
    notFound: "Company not found.",
    exists: "Company already exists.",
    notRight: "Company is not right.",
    notAuthorized: "Company not authorized.",
    registerFailed: "Company registration failed.",
    getPricePlanFailed: "Failed to get company price plan.",
    emailCheckFailed: "Failed to check email.",
  },
  superAdmin: {
    notFound: "Super admin not found.",
    exists: "Super admin already exists.",
    notRight: "Super admin is not right.",
    notAuthorized: "Super admin not authorized.",
    loginFailed: "Super admin login failed.",
    registerFailed: "Super admin registration failed.",
    logoutFailed: "Super admin logout failed.",
    fetchFailed: "Failed to fetch super admin.",
    invalidInviteLink: "Invalid invite link.",
    generateInviteLinkFailed: "Failed to generate invite link.",
    fetchInviteLinksFailed: "Failed to fetch invite links.",
    deleteAllCompaniesFailed: "Failed to delete all companies.",
    fetchAllCompaniesFailed: "Failed to fetch all companies.",
    invitationLinksFetchFailed: "Failed to fetch invitation links.",
    setCompanyPlansFailed: "Failed to set company plans.",
  },
  nameCardCompanyConfig: {
    notFound: "Company config not found.",
    fetchFailed: "Failed to fetch company config.",
    createFailed: "Failed to create company config.",
    updateFailed: "Failed to update company config.",
    deleteFailed: "Failed to delete company config.",
    fetchAllFailed: "Failed to fetch all company configs.",
    uploadFailed: "Failed to upload company config.",
    setGlobalFailed: "Failed to set company config as global.",
  },
  nameCardStyleConfig: {
    notFound: "Style config not found.",
    fetchFailed: "Failed to fetch style config.",
    createFailed: "Failed to create style config.",
    updateFailed: "Failed to update style config.",
    deleteFailed: "Failed to delete style config.",
    fetchAllFailed: "Failed to fetch all style configs.",
    setGlobalFailed: "Failed to set style config as global.",
  },
  notFileUploaded: "No file uploaded.",
  fileTypeNotProvide: "File type not provided.",
  AWS: "AWS error.",
  preSignedUrl: "Error generating pre-signed URL.",
  YOOVUser: "YOOV user error.",
};
