import axios from "axios";
import axiosApi from "../../utils/axiosApi";
import SuperAdminActions from "../actions/superAdminActions";
import { getApiUrl } from "../../utils/baseApiUrl";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { errorMessages } from "../../error/errorMessages";

const authApiURL = getApiUrl("/api/v1/super-admin");

const login = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApiURL}/login`, data);
    const { accessToken, refreshToken } = response.data;
    saveTokens({ accessToken, refreshToken });
    dispatch(SuperAdminActions.loginSuccess({ accessToken, refreshToken }));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      SuperAdminActions.loginFailure(
        errorMessage || errorMessages.superAdmin.loginFailed
      )
    );
  }
};

const logout = () => (dispatch) => {
  removeTokens();
  dispatch(SuperAdminActions.logout());
};

const saveTokens = (tokens) => {
  localStorage.setItem("authToken", tokens.accessToken);
  localStorage.setItem("refreshToken", tokens.refreshToken);
};

const removeTokens = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
};

const getAllCompanies = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(`${authApiURL}/companies`);
    dispatch(SuperAdminActions.getAllCompaniesSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      SuperAdminActions.getAllCompaniesFailure(
        errorMessage || errorMessages.superAdmin.fetchAllCompaniesFailed
      )
    );
  }
};

const setCompanyPlans = (companyId, limit, pricePlan) => async (dispatch) => {
  try {
    await axiosApi.put(`${authApiURL}/company-plan/${companyId}`, {
      limit,
      pricePlan,
    });
    dispatch(SuperAdminActions.setCompanyPlansSuccess());
    dispatch(getAllCompanies());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      SuperAdminActions.setCompanyPlansFailure(
        errorMessage || errorMessages.superAdmin.setCompanyPlansFailed
      )
    );
  }
};

const generateInvitationLink =
  (usageLimit = null) =>
  async (dispatch) => {
    try {
      const response = await axiosApi.post(`${authApiURL}/invitation-opt`, {
        usageLimit,
      });
      dispatch(SuperAdminActions.generateInvitationLinkSuccess(response.data));
      return response.data;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(
        SuperAdminActions.generateInvitationLinkFailure(
          errorMessage || errorMessages.superAdmin.generateInviteLinkFailed
        )
      );
    }
  };

const getAllInvitationLinks = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(`${authApiURL}/invitation-links`);
    dispatch(SuperAdminActions.getAllInvitationLinksSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      SuperAdminActions.getAllInvitationLinksFailure(
        errorMessage || errorMessages.superAdmin.invitationLinksFetchFailed
      )
    );
  }
};

const isValidInvitationLink = (token) => async (dispatch) => {
  try {
    const response = await axiosApi.get(
      `${authApiURL}/invitation-valid/${token}`
    );
    dispatch(SuperAdminActions.isValidInvitationLink(response.data.isValid));
  } catch (error) {
    return null;
  }
};

const deleteCompany = (companyId) => async (dispatch) => {
  try {
    await axiosApi.delete(`${authApiURL}/company/${companyId}`);
    dispatch(SuperAdminActions.deleteCompanySuccess(companyId));
    dispatch(getAllCompanies());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      SuperAdminActions.deleteCompanyFailure(
        errorMessage || errorMessages.superAdmin.deleteCompanyFailed
      )
    );
  }
};

const SuperAdminThunks = {
  login,
  logout,
  getAllCompanies,
  generateInvitationLink,
  getAllInvitationLinks,
  isValidInvitationLink,
  deleteCompany,
  setCompanyPlans,
};

export default SuperAdminThunks;
