import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILURE,
  GENERATE_INVITATION_LINK_SUCCESS,
  GENERATE_INVITATION_LINK_FAILURE,
  GET_ALL_INVITATION_LINKS_SUCCESS,
  GET_ALL_INVITATION_LINKS_FAILURE,
  IS_VALID_INVITATION_LINK,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
} from "../actions/superAdminActions";

const initialState = {
  companies: [],
  link: "",
  invitationLinks: [],
  isAuthenticated: false,
  isValidToken: false,
  error: null,
};
const superAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        error: null,
      };
    case GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GENERATE_INVITATION_LINK_SUCCESS:
      return {
        ...state,
        link: action.payload,
        error: null,
      };
    case GENERATE_INVITATION_LINK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_INVITATION_LINKS_SUCCESS:
      return {
        ...state,
        invitationLinks: action.payload,
        error: null,
      };
    case GET_ALL_INVITATION_LINKS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case IS_VALID_INVITATION_LINK:
      return {
        ...state,
        isValidToken: action.payload,
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case DELETE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default superAdminReducer;
