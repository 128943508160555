import axiosApi from "../../../utils/axiosApi";
import NameCardCompanyConfigActions from "../../actions/nameCard/companyConfigActions";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { errorMessages } from "../../../error/errorMessages";

const apiURL = "name-card/company-config";

const getTarget = (id) => async (dispatch) => {
  try {
    const response = await axiosApi.get(`${apiURL}/${id}`);
    dispatch(NameCardCompanyConfigActions.getTargetSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardCompanyConfigActions.getTargetFailure(
        errorMessage || errorMessages.nameCardCompanyConfig.notFound
      )
    );
  }
};

const getAll = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(apiURL);
    dispatch(NameCardCompanyConfigActions.getAllSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardCompanyConfigActions.getAllFailure(
        errorMessage || errorMessages.nameCardCompanyConfig.fetchAllFailed
      )
    );
  }
};

const createTarget = (data) => async (dispatch) => {
  try {
    const { imageType, companyImg, companyImgPreSignedUrl, ...newData } = data;
    const response = await axiosApi.post(apiURL, newData);
    const { id } = response.data;
    if (imageType && companyImg) {
      await dispatch(uploadTargetImg(companyImg, imageType, id));
    }
    dispatch(NameCardCompanyConfigActions.createTargetSuccess(response.data));
    dispatch(getAll());
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardCompanyConfigActions.createTargetFailure(
        errorMessage || errorMessages.nameCardCompanyConfig.createFailed
      )
    );
  }
};

const updateTarget = (data) => async (dispatch) => {
  try {
    const { imageType, companyImg, companyImgPreSignedUrl, id, ...newData } =
      data;
    if (imageType && companyImg) {
      await dispatch(uploadTargetImg(companyImg, imageType, id));
    }
    const response = await axiosApi.put(`${apiURL}/${id}`, newData);
    dispatch(NameCardCompanyConfigActions.updateTargetSuccess(response.data));
    dispatch(NameCardCompanyConfigActions.getTargetSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardCompanyConfigActions.updateTargetFailure(
        errorMessage || errorMessages.nameCardCompanyConfig.updateFailed
      )
    );
  }
};

const deleteTarget = (id) => async (dispatch) => {
  try {
    const response = await axiosApi.delete(`${apiURL}/${id}`);
    dispatch(NameCardCompanyConfigActions.deleteTargetSuccess(response.data));
    dispatch(getAll());
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardCompanyConfigActions.deleteTargetFailure(
        errorMessage || errorMessages.nameCardCompanyConfig.deleteFailed
      )
    );
  }
};

const uploadTargetImg =
  (file, fileType, companyConfigId) => async (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType);
    try {
      const response = await axiosApi.post(
        `${apiURL}/upload-files/${companyConfigId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return {
        fileType,
        url: response.data.uploadedFileUrls[0][fileType],
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(
        NameCardCompanyConfigActions.uploadTargetImgFailure(
          errorMessage || errorMessages.nameCardCompanyConfig.uploadFailed
        )
      );
    }
  };

const setTargetGlobal = (id) => async (dispatch) => {
  try {
    await axiosApi.put(`${apiURL}/global/${id}`);
    dispatch(NameCardCompanyConfigActions.setTargetGlobalSuccess(id));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardCompanyConfigActions.setTargetGlobalFailure(
        errorMessage || errorMessages.nameCardCompanyConfig.setGlobalFailed
      )
    );
  }
};

const NameCardCompanyConfigThunks = {
  getTarget,
  getAll,
  createTarget,
  updateTarget,
  deleteTarget,
  uploadTargetImg,
  setTargetGlobal,
};

export default NameCardCompanyConfigThunks;
