import styled from "styled-components";
import { Box, Avatar, Tabs, Tab, TableCell } from "@mui/material";
import {
  GlobalColor,
  GlobalFontSize,
  GlobalBorderRadius,
} from "../components/constant/Global";

export const BaseRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BaseColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
`;
export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Title = styled.h3`
  margin-top: 0;
  color: ${GlobalColor.primary};
`;

export const TextBoxWrapper = styled(FlexStart)`
  font-size: 1.5rem;
  gap: 2rem;
  width: 600px;
  align-items: center;
  @media (max-width: 600px) {
    font-size: 1rem;
`;

export const TextItemWrapper = styled(FlexStart)`
  align-items: center;
  font-size: 1rem;
`;

export const FlexStartTitle = styled(Title)`
  display: flex;
  font-size: ${GlobalFontSize.extraLarge};
  justify-content: flex-start;
  @media (max-width: 600px) {
    font-size: ${GlobalFontSize.large};
  }
`;

export const HeaderWraper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const FlexStartColumn = styled(FlexStart)`
  flex-direction: column;
`;

export const ComponentWrapper = styled(BaseColumnWrapper)`
  gap: 1rem;
  margin: 1rem 0;
`;

export const BaseContainer = styled(BaseRowWrapper)`
  width: 100%;
  height: 100vh;
`;

export const BaseContainerColumn = styled(BaseColumnWrapper)`
  width: 100%;
`;

export const BaseContainerDashboard = styled(Box)`
  margin: 20px;
  margin-top: 84px;
`;

export const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${GlobalFontSize.small};
  font-weight: bold;
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: ${GlobalBorderRadius.small};
  color: ${GlobalColor.white};
  background-color: ${GlobalColor.primary};
  &:hover {
    background-color: ${GlobalColor.hover};
  }
`;

export const BaseCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DrawerWidth = 200;

export const CompnayImage = styled.img`
  width: 140px;
  max-height: 50px;
  margin: 48px auto 24px 24px;
`;

export const StyledAvatar = styled(Avatar)`
  position: relative;
  cursor: pointer;
  &.MuiAvatar-root {
    width: 100px;
    height: 100px;
  }
`;

export const StyledAvatarSmall = styled(Avatar)`
  &:hover {
    scale: ${(props) => (props.is_editing ? "" : 1.5)};
  }
  &.MuiAvatar-root {
    width: 36px;
    height: 36px;
  }
`;

export const StyledAvatarRegular = styled(Avatar)`
  &:hover {
    scale: ${(props) => (props.is_editing ? "" : 1.5)};
  }
  &.MuiAvatar-root {
    width: 48px;
    height: 48px;
  }
`;

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${GlobalColor.primary};
  }
`;

export const StyledTab = styled(Tab)`
  &.Mui-selected {
    color: ${GlobalColor.primary} !important;
  }
`;

export const StyledTableCell = styled(TableCell)`
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.MuiTableCell-head {
    font-size: ${GlobalFontSize.small};
    font-weight: bold;
    color: ${GlobalColor.primary};
  }
  @media (max-width: 600px) {
    max-width: 30px;
  }
`;

export const CompanyLogo = styled.img`
  width: 36px;
  height: auto;
`;

export const AddContainer = styled(BaseColumnWrapper)`
  // padding: 12px;
  width: 300px;
  margin-left: auto;
  border: 1px solid ${GlobalColor.lightGrey};
  background-color: ${GlobalColor.white};
  @media (max-width: 600px) {
    width: 100%;
    padding: 12px 0;
  }
`;

export const ImportContainer = styled(BaseColumnWrapper)`
  // padding: 12px;
  width: 300px;
  margin-right: auto;
  border: 1px solid ${GlobalColor.lightGrey};
  background-color: ${GlobalColor.white};
  @media (max-width: 600px) {
    width: 100%;
    padding: 12px 0;
  }
`;

export const DashboardWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: ${GlobalBorderRadius.small};
  background-color: ${GlobalColor.white};
`;

export const NumberWrapper = styled.div`
  background-color: ${GlobalColor.green};
  color: ${GlobalColor.white};
  font-weight: bold;
  padding: 4px 0.5rem;
  font-size: 1rem;
  border-radius: 0.75rem;
`;

export const BaseTextWrapper = styled(FlexStart)`
  align-items: center;
  gap: 0.5rem;
`;

export const BaseWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const HeaderRowWrapper = styled(BaseColumnWrapper)`
  align-items: flex-start;
  width: 50%;
  margin-left: 4rem;
  gap: 1rem;
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 2rem;
    display: flex;
    justify-content: flex-start;
  }
`;

export const HeaderRowCenterWrapper = styled(BaseRowWrapper)`
  justify-content: center;
  gap: 1rem;
`;

export const ItemLabel = styled(FlexStart)`
  width: 160px;
`;
