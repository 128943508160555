import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import InfoTable from "./InfoTable";
import NameCardUserThunks from "../../redux/thunks/nameCard/userThunks";
import { GlobalUserTypes } from "../constant/Global";
import { useUserType } from "../context/UserTypeContext";

const NameCardUsersInfoTable = () => {
  const dispatch = useDispatch();
  const { setUserType } = useUserType();

  useEffect(() => {
    dispatch(NameCardUserThunks.getAll());
  }, [dispatch]);

  useEffect(() => {
    setUserType(GlobalUserTypes.nameCardUser);
  }, [setUserType]);

  return <InfoTable />;
};

export default NameCardUsersInfoTable;
