import axios from "axios";
import CompanyActions from "../actions/companyActions";
import { getApiUrl } from "../../utils/baseApiUrl";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { errorMessages } from "../../error/errorMessages";

const apiURL = getApiUrl("/api/v1/company");

const register = (invitationCode, data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiURL}/register?invitationCode=${invitationCode}`,
      data
    );
    dispatch(CompanyActions.registerSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      CompanyActions.registerFailure(
        errorMessage || errorMessages.company.registerFailed
      )
    );
  }
};

const isEmailExists = (email) => async (dispatch) => {
  try {
    const response = await axios.get(`${apiURL}/is-exist/${email}`);
    dispatch(CompanyActions.isEmailExists(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      CompanyActions.isEmailExists(
        false,
        errorMessage || errorMessages.company.emailCheckFailed
      )
    );
  }
};

const CompanyThunks = {
  register,
  isEmailExists,
};

export default CompanyThunks;
