import { useEffect } from "react";
import posthog from "posthog-js";

const usePosthog = (apiKey, options) => {
  useEffect(() => {
    if (!posthog.__initialized) {
      posthog.init(apiKey, options);
    }
  }, [apiKey, options]);

  return posthog;
};

export default usePosthog;
