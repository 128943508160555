import React from "react";
import styled from "styled-components";
import { GlobalColor } from "./Global";
import { AccountCircleIcon, LogoutIcon, MenuOpenIcon } from "./Icons";
import { useDispatch } from "react-redux";
import AuthThunks from "../../redux/thunks/authThunks";
import { AppBar, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileDialog from "../dialogs/ProfileDialog";
import { NavbarButton } from "../../styled/Button";
import { BaseCenterWrapper, DrawerWidth } from "../../styled/BaseStyles";
import NavbarMenu from "../navbar/NavbarMenu";
const NavbarLeft = styled(BaseCenterWrapper)`
  margin-left: ${DrawerWidth}px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const NavbarRight = styled(BaseCenterWrapper)`
  margin-right: 1rem;
  @media (max-width: 600px) {
    display: none;
  }
`;

const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MobileNavbarRight = styled.button`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    border: none;
    background: none;
  }
`;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const user = useSelector((state) => state.user.target);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleProfile = () => {
    setOpenProfile(true);
  };
  const handleLogout = () => {
    dispatch(AuthThunks.userLogout());
  };
  const handleClose = () => {
    setOpenProfile(false);
  };

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <AppBar position="fixed">
      <Toolbar
        sx={{
          justifyContent: "flex-end",
          background: `${GlobalColor.white}`,
          color: `${GlobalColor.primary}`,
        }}
      >
        <NavbarWrapper>
          <NavbarLeft>
            {user && (
              <NavbarButton onClick={() => navigator("/")}>
                Hi {user.name}
              </NavbarButton>
            )}
          </NavbarLeft>
          <NavbarRight>
            <NavbarButton onClick={handleProfile}>
              <AccountCircleIcon />
              My Profile
            </NavbarButton>
            <NavbarButton onClick={handleLogout}>
              <LogoutIcon />
              Logout
            </NavbarButton>
          </NavbarRight>
          <MobileNavbarRight onClick={handleMenuClick}>
            <MenuOpenIcon />
          </MobileNavbarRight>
        </NavbarWrapper>
      </Toolbar>
      {openProfile && (
        <ProfileDialog
          open={openProfile}
          currentUser={user}
          handleClose={handleClose}
        />
      )}
      {openMenu && <NavbarMenu handleClose={() => setOpenMenu(false)} />}
    </AppBar>
  );
};

export default Navbar;
