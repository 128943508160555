export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const IS_EMAIL_EXISTS = "IS_EMAIL_EXISTS";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAILURE = "GET_ALL_COMPANIES_FAILURE";
export const RESET_REGISTER_STATUS = "RESET_REGISTER_STATUS";

const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

const isEmailExists = (email) => ({
  type: IS_EMAIL_EXISTS,
  payload: email,
});

const getAllCompaniesSuccess = (companies) => ({
  type: GET_ALL_COMPANIES_SUCCESS,
  payload: companies,
});

const getAllCompaniesFailure = (error) => ({
  type: GET_ALL_COMPANIES_FAILURE,
  payload: error,
});

const resetRegisterStatus = () => ({
  type: RESET_REGISTER_STATUS,
});

const CompanyActions = {
  registerSuccess,
  registerFailure,
  isEmailExists,
  getAllCompaniesSuccess,
  getAllCompaniesFailure,
  resetRegisterStatus,
};

export default CompanyActions;
