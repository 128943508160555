import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DeleteForeverIcon, CloseIcon } from "../constant/Icons";
import { DeleteButton, CancelButton } from "../../styled/Button";

const DeleteConfirmDialog = ({ openDialog, onClose, handleDelete }) => {
  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose}>
          <CloseIcon />
          Cancel
        </CancelButton>
        <DeleteButton onClick={handleDelete} autoFocus>
          <DeleteForeverIcon />
          Delete
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmDialog;
