import axiosApi from "../../../utils/axiosApi";
import NameCardStyleConfigActions from "../../actions/nameCard/styleConfigActions";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { errorMessages } from "../../../error/errorMessages";

const apiURL = "name-card/style-config";

export const getTarget = (id) => async (dispatch) => {
  try {
    const response = await axiosApi.get(`${apiURL}/${id}`);
    dispatch(NameCardStyleConfigActions.getTargetSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardStyleConfigActions.getTargetFailure(
        errorMessage || errorMessages.nameCardStyleConfig.notFound
      )
    );
  }
};

export const getAll = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(apiURL);
    dispatch(NameCardStyleConfigActions.getAllSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardStyleConfigActions.getAllFailure(
        errorMessage || errorMessages.nameCardStyleConfig.fetchAllFailed
      )
    );
  }
};

export const createTarget = (data) => async (dispatch) => {
  try {
    const response = await axiosApi.post(apiURL, data);
    dispatch(NameCardStyleConfigActions.createTargetSuccess(response.data));
    dispatch(getAll());
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardStyleConfigActions.createTargetFailure(
        errorMessage || errorMessages.nameCardStyleConfig.createFailed
      )
    );
  }
};

export const updateTarget = (data) => async (dispatch) => {
  try {
    const response = await axiosApi.put(`${apiURL}/${data.id}`, data);
    dispatch(NameCardStyleConfigActions.updateTargetSuccess(response.data));
    dispatch(getAll());
    dispatch(getTarget(data.id));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardStyleConfigActions.updateTargetFailure(
        errorMessage || errorMessages.nameCardStyleConfig.updateFailed
      )
    );
  }
};

export const deleteTarget = (id) => async (dispatch) => {
  try {
    const response = await axiosApi.delete(`${apiURL}/${id}`);
    dispatch(NameCardStyleConfigActions.deleteTargetSuccess(response.data));
    await dispatch(getAll());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardStyleConfigActions.deleteTargetFailure(
        errorMessage || errorMessages.nameCardStyleConfig.deleteFailed
      )
    );
  }
};

export const setTargetGlobal = (id) => async (dispatch) => {
  try {
    await axiosApi.put(`${apiURL}/global/${id}`);
    dispatch(NameCardStyleConfigActions.setTargetGlobalSuccess(id));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardStyleConfigActions.setTargetGlobalFailure(
        errorMessage || errorMessages.nameCardStyleConfig.setGlobalFailed
      )
    );
  }
};

const NameCardStyleConfigThunks = {
  getTarget,
  getAll,
  createTarget,
  updateTarget,
  deleteTarget,
  setTargetGlobal,
};

export default NameCardStyleConfigThunks;
