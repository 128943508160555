import React from "react";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import {
  FlexStartTitle,
  HeaderWraper,
  DashboardWrapper,
  NumberWrapper,
  FlexStartColumn,
} from "../../styled/BaseStyles";
import { ViewCountTextWrapper, SmallTextBoxWrapper } from "../../styled/Text";
import { useSelector } from "react-redux";
import NameCardViewChart from "./NameCardViewChart";

const AnalyticsDashboard = () => {
  const allNameCardActivities = useSelector(
    (state) => state.nameCardAnalytics.target
  );

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <DashboardWrapper>
        <HeaderWraper>
          <FlexStartTitle>Name Card Analytics</FlexStartTitle>
          <FlexStartColumn>
            <ViewCountTextWrapper>
              <SmallTextBoxWrapper>Total Name Cards</SmallTextBoxWrapper>
              <NumberWrapper>{allNameCardActivities.length}</NumberWrapper>
            </ViewCountTextWrapper>
            <ViewCountTextWrapper>
              <SmallTextBoxWrapper>Total Views</SmallTextBoxWrapper>
              <NumberWrapper>
                {allNameCardActivities.flat().length}
              </NumberWrapper>
            </ViewCountTextWrapper>
          </FlexStartColumn>
        </HeaderWraper>
        <NameCardViewChart data={allNameCardActivities.flat()} />
      </DashboardWrapper>
    </StyleSheetManager>
  );
};

export default AnalyticsDashboard;
