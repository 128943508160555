import React, { useState } from "react";
import { ImportContainer } from "../../styled/BaseStyles";
import { ImportButton } from "../../styled/Button";
import ImportDialog from "../dialogs/ImportDialog";
import UserThunks from "../../redux/thunks/userThunks";
import UserActions from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import { NotiAction, NotiName } from "../constant/Notification";
import NotiDialog from "../dialogs/NotiDialog";
import useStatusChangeNotifications from "../../hooks/useStatusChangeNotifications";

function ImportUser() {
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [openNotiDialog, setOpenNotiDialog] = useState(false);
  const [notiInfo, setNotiInfo] = useState("");
  const dispatch = useDispatch();
  const { resetImportUsersStatus } = UserActions;
  const statusConfigs = [
    {
      stateSlice: "user",
      statusKey: "importUsersStatus",
      action: NotiAction.Upload,
      name: NotiName.User,
      resetAction: resetImportUsersStatus,
    },
  ];

  const handleCloseNotiDialog = useStatusChangeNotifications(
    statusConfigs,
    setNotiInfo,
    setOpenNotiDialog
  );

  const handleImport = async (file) => {
    dispatch(UserThunks.importUsers(file));
  };

  const handleDownloadTemplate = () => {
    dispatch(UserThunks.downloadTemplate());
  };
  return (
    <ImportContainer>
      <ImportButton onClick={() => setIsImportDialogOpen(true)}>
        Import User
      </ImportButton>
      <ImportDialog
        open={isImportDialogOpen}
        onClose={() => setIsImportDialogOpen(false)}
        onImport={handleImport}
        downloadTemplate={handleDownloadTemplate}
      />
      {openNotiDialog && (
        <NotiDialog
          openDialog={openNotiDialog}
          onClose={handleCloseNotiDialog}
          notiInfo={notiInfo}
        />
      )}
    </ImportContainer>
  );
}

export default ImportUser;
