export const GET_NAME_CARD_COLLECTIONS_SUCCESS =
  "GET_NAME_CARD_COLLECTIONS_SUCCESS";
export const GET_NAME_CARD_COLLECTIONS_FAILURE =
  "GET_NAME_CARD_COLLECTIONS_FAILURE";
export const ADD_NAME_CARD_COLLECTION_SUCCESS =
  "ADD_NAME_CARD_COLLECTION_SUCCESS";
export const ADD_NAME_CARD_COLLECTION_FAILURE =
  "ADD_NAME_CARD_COLLECTION_FAILURE";
export const DELETE_NAME_CARD_COLLECTION_SUCCESS =
  "DELETE_NAME_CARD_COLLECTION_SUCCESS";
export const DELETE_NAME_CARD_COLLECTION_FAILURE =
  "DELETE_NAME_CARD_COLLECTION_FAILURE";
export const UPDATE_NAME_CARD_COLLECTION_SUCCESS =
  "UPDATE_NAME_CARD_COLLECTION_SUCCESS";
export const UPDATE_NAME_CARD_COLLECTION_FAILURE =
  "UPDATE_NAME_CARD_COLLECTION_FAILURE";
export const IS_ALREADY_IN_COLLECTION = "IS_ALREADY_IN_COLLECTION";
export const RESET_ADD_COLLECTION_STATUS = "RESET_ADD_COLLECTION_STATUS";
export const RESET_DELETE_COLLECTION_STATUS = "RESET_DELETE_COLLECTION_STATUS";
export const RESET_UPDATE_COLLECTION_STATUS = "RESET_UPDATE_COLLECTION_STATUS";

const getAllSuccess = (data) => ({
  type: GET_NAME_CARD_COLLECTIONS_SUCCESS,
  payload: data,
});

const getAllFailure = (error) => ({
  type: GET_NAME_CARD_COLLECTIONS_FAILURE,
  payload: error,
});

const addTargetSuccess = (data) => ({
  type: ADD_NAME_CARD_COLLECTION_SUCCESS,
  payload: data,
});

const addTargetFailure = (error) => ({
  type: ADD_NAME_CARD_COLLECTION_FAILURE,
  payload: error,
});

const deleteTargetSuccess = (data) => ({
  type: DELETE_NAME_CARD_COLLECTION_SUCCESS,
  payload: data,
});

const deleteTargetFailure = (error) => ({
  type: DELETE_NAME_CARD_COLLECTION_FAILURE,
  payload: error,
});

const updateTargetSuccess = (data) => ({
  type: UPDATE_NAME_CARD_COLLECTION_SUCCESS,
  payload: data,
});

const updateTargetFailure = (error) => ({
  type: UPDATE_NAME_CARD_COLLECTION_FAILURE,
  payload: error,
});

const isAlreadyInCollection = (data) => ({
  type: IS_ALREADY_IN_COLLECTION,
  payload: data,
});

const resetAddNameCardCollectionStatus = () => ({
  type: RESET_ADD_COLLECTION_STATUS,
});

const resetDeleteNameCardCollectionStatus = () => ({
  type: RESET_DELETE_COLLECTION_STATUS,
});

const resetUpdateNameCardCollectionStatus = () => ({
  type: RESET_UPDATE_COLLECTION_STATUS,
});

const NameCardCollectionActions = {
  getAllSuccess,
  getAllFailure,
  addTargetSuccess,
  addTargetFailure,
  deleteTargetSuccess,
  deleteTargetFailure,
  updateTargetSuccess,
  updateTargetFailure,
  isAlreadyInCollection,
  resetAddNameCardCollectionStatus,
  resetDeleteNameCardCollectionStatus,
  resetUpdateNameCardCollectionStatus,
};

export default NameCardCollectionActions;
