const FormatDateTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "Asia/Hong_Kong", // Set the timezone
  };
  return new Date(dateTimeString).toLocaleDateString("en-US", options);
};

export default FormatDateTime;
