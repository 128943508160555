export const NotiType = {
  Success: "Success",
  Error: "Error",
  Warning: "Warning",
  Info: "Info",
  Free: "Free Plan",
  Basic: "Basic Plan",
  Premium: "Premium Plan",
  Enterprise: "Enterprise Plan",
  InvalidInvitationLink: "Invalid Invitation Link (Expired or Registered)",
  Duplicate: "Duplicate",
  NotFound: "Not Found",
  AlreadyInCollection: "Already In Collection",
};

export const NotiAction = {
  Delete: "Delete",
  Update: "Update",
  Create: "Create",
  Set: "Set",
  Generate: "Generate",
  Register: "Register",
  NameCardPlan: "Name Card ",
  UserPlan: "User",
  Company: "Company",
  ResetPassword: "Reset Password",
  Login: "Login",
  Add: "Add",
  Upload: "Import",
};

export const NotiName = {
  CompanyConfig: "Company Config",
  StyleConfig: "Style Config",
  User: "User",
  NameCard: "Name Card",
  GlobalCompanyConfig: "Global Company Config",
  GlobalStyleConfig: "Global Style Config",
  InvitationLink: "Invitation Link",
  UserPlan: "User",
  NameCardPlan: "Name Card",
  Password: "Password",
  Company: "Company",
  Platform: "Platform",
};

const notificationMessages = (notiName) => {
  return {
    [NotiAction.Delete]: {
      [NotiType.Success]: `${notiName} has been deleted successfully`,
      [NotiType.Error]: `Failed to delete ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to delete ${notiName}`,
      [NotiType.Info]: `Deleting ${notiName}`,
    },
    [NotiAction.Update]: {
      [NotiType.Success]: `${notiName} has been updated successfully`,
      [NotiType.Error]: `Failed to update ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to update ${notiName}`,
      [NotiType.Info]: `Updating ${notiName}`,
    },
    [NotiAction.Create]: {
      [NotiType.Success]: `${notiName} has been created successfully`,
      [NotiType.Error]: `Failed to create ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to create ${notiName}`,
      [NotiType.Info]: `Creating ${notiName}`,
    },
    [NotiAction.Set]: {
      [NotiType.Success]: `${notiName} has been set successfully`,
      [NotiType.Error]: `Failed to set ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to set ${notiName}`,
      [NotiType.Info]: `Setting ${notiName}`,
    },
    [NotiAction.Generate]: {
      [NotiType.Success]: `${notiName} has been generated successfully`,
      [NotiType.Error]: `Failed to generate ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to generate ${notiName}`,
      [NotiType.Info]: `Generating ${notiName}`,
    },
    [NotiAction.Register]: {
      [NotiType.Success]: `${notiName} has been registered successfully`,
      [NotiType.Error]: `Failed to register ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to register ${notiName}`,
      [NotiType.Info]: `Registering ${notiName}`,
    },
    [NotiAction.ResetPassword]: {
      [NotiType.Success]: `${notiName} has been reset successfully`,
      [NotiType.Error]: `Failed to reset ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to reset ${notiName}`,
      [NotiType.Info]: `Resetting ${notiName}`,
    },
    [NotiAction.Login]: {
      [NotiType.Success]: `Login successfully`,
      [NotiType.Error]: `Failed to login, either email or password is incorrect`,
      [NotiType.Warning]: `Are you sure you want to login`,
      [NotiType.Info]: `Logging in`,
    },
    [NotiAction.Add]: {
      [NotiType.Success]: `${notiName} has been added successfully`,
      [NotiType.Error]: `Failed to add ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to add ${notiName}`,
      [NotiType.Info]: `Adding ${notiName}`,
      [NotiType.Duplicate]: `This ${notiName} already exists`,
      [NotiType.NotFound]: `This ${notiName} does not exist`,
      [NotiType.AlreadyInCollection]: `This ${notiName} is already in your collection`,
    },
    [NotiAction.Upload]: {
      [NotiType.Success]: `${notiName} has been imported successfully`,
      [NotiType.Error]: `Failed to import ${notiName}`,
      [NotiType.Warning]: `Are you sure you want to import ${notiName}`,
      [NotiType.Info]: `Importing ${notiName}`,
    },
  };
};

const getNotiTitle = (action, name, type) => {
  return `${action} ${name} ${type}`;
};
const getNotiMessage = (action, name, type) => {
  return notificationMessages(name)[action][type];
};

export const getNotiInformation = (action, name, type, extra = "") => {
  return {
    title: getNotiTitle(action, name, type),
    message: getNotiMessage(action, name, type),
    extra: extra,
    status: type,
  };
};

const getPricePlanMessages = (notiName) => {
  return {
    [NotiName.NameCardPlan]: {
      [NotiType.Free]: `Each user can only have 10 name card`,
      [NotiType.Basic]: `Each user  can have up to 20 name cards`,
      [NotiType.Premium]: `Each user  can have up to 50 name cards`,
      [NotiType.Enterprise]: `Each user  can have up to 20 name cards`,
    },
    [NotiName.UserPlan]: {
      [NotiType.Free]: `Reach Limited User`,
      [NotiType.Basic]: `Reach Limited User`,
      [NotiType.Premium]: `Reach Limited User`,
      [NotiType.Enterprise]: `Reach Limited User`,
    },
  };
};

export const getPricePlanMessage = (name, type) => {
  return getPricePlanMessages(name)[name][type];
};

export const getPricePlanInfomation = (action, name, type, extra = "") => {
  return {
    title: getNotiTitle(action, name, type),
    message: getPricePlanMessage(name, type),
    extra: extra,
    status: type,
  };
};
