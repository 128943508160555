// reducer.js
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_WITH_YOOV_FAILURE,
  LOGIN_WITH_YOOV_SUCCESS,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  GET_RESET_EMAIL_SUCCESS,
  GET_RESET_EMAIL_FAILURE,
} from "../actions/authActions";

const initialState = {
  accessToken: localStorage.getItem("authToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  isAuthenticated: !!localStorage.getItem("authToken"),
  pricePlan: "Free",
  userLimit: 5,
  reset: {
    email: "",
    status: "",
  },
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        isAuthenticated: true,
        pricePlan: action.payload.companyPlan,
        userLimit: action.payload.userLimit,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case LOGIN_WITH_YOOV_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        isAuthenticated: true,
        error: null,
      };
    case LOGIN_WITH_YOOV_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset: {
          email: "",
          status: "success",
        },
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        reset: {
          email: action.payload,
        },
      };
    case GET_RESET_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
