export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAILURE = "GET_ALL_COMPANIES_FAILURE";
export const GENERATE_INVITATION_LINK_SUCCESS =
  "GENERATE_INVITATION_LINK_SUCCESS";
export const GENERATE_INVITATION_LINK_FAILURE =
  "GENERATE_INVITATION_LINK_FAILURE";
export const GET_ALL_INVITATION_LINKS_SUCCESS =
  "GET_ALL_INVITATION_LINKS_SUCCESS";
export const GET_ALL_INVITATION_LINKS_FAILURE =
  "GET_ALL_INVITATION_LINKS_FAILURE";
export const IS_VALID_INVITATION_LINK = "IS_VALID_INVITATION_LINK";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";
export const SET_COMPANY_PLANS_SUCCESS = "SET_COMPANY_PLANS_SUCCESS";
export const SET_COMPANY_PLANS_FAILURE = "SET_COMPANY_PLANS_FAILURE";

const loginSuccess = (tokens) => ({
  type: LOGIN_SUCCESS,
  payload: tokens,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

const logout = () => ({
  type: LOGOUT,
});

const getAllCompaniesSuccess = (companies) => ({
  type: GET_ALL_COMPANIES_SUCCESS,
  payload: companies,
});

const getAllCompaniesFailure = (error) => ({
  type: GET_ALL_COMPANIES_FAILURE,
  payload: error,
});

const generateInvitationLinkSuccess = (link) => ({
  type: GENERATE_INVITATION_LINK_SUCCESS,
  payload: link,
});

const generateInvitationLinkFailure = (error) => ({
  type: GENERATE_INVITATION_LINK_FAILURE,
  payload: error,
});

const getAllInvitationLinksSuccess = (links) => ({
  type: GET_ALL_INVITATION_LINKS_SUCCESS,
  payload: links,
});

const getAllInvitationLinksFailure = (error) => ({
  type: GET_ALL_INVITATION_LINKS_FAILURE,
  payload: error,
});

const isValidInvitationLink = (token) => ({
  type: IS_VALID_INVITATION_LINK,
  payload: token,
});

const deleteCompanySuccess = (companyId) => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: companyId,
});

const deleteCompanyFailure = (error) => ({
  type: DELETE_COMPANY_FAILURE,
  payload: error,
});

const setCompanyPlansSuccess = (companyId, plans) => ({
  type: SET_COMPANY_PLANS_SUCCESS,
  payload: { companyId, plans },
});

const setCompanyPlansFailure = (error) => ({
  type: SET_COMPANY_PLANS_FAILURE,
  payload: error,
});

const SuperAdminActions = {
  loginSuccess,
  loginFailure,
  logout,
  getAllCompaniesSuccess,
  getAllCompaniesFailure,
  generateInvitationLinkSuccess,
  generateInvitationLinkFailure,
  getAllInvitationLinksSuccess,
  getAllInvitationLinksFailure,
  isValidInvitationLink,
  deleteCompanySuccess,
  deleteCompanyFailure,
  setCompanyPlansSuccess,
  setCompanyPlansFailure,
};

export default SuperAdminActions;
