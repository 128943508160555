import "./App.css";
import { useEffect } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";
import usePosthog from "./hooks/usePostHog";
import store from "./store";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Callback from "./pages/CallBack";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import NotFoundPage from "./pages/NotFoundPage";
import React, { Suspense } from "react";
import UnauthorizedDialog from "./components/dialogs/UnauthorizedDialog";

const LazySuperAdmin = React.lazy(() =>
  import("./pages/superAdmin/SuperAdmin")
);
const LazySuperAdminDashBoard = React.lazy(() =>
  import("./pages/superAdmin/Dashboard")
);
const LazyAdmin = React.lazy(() => import("./pages/Admin"));
const LazyNameCardCollection = React.lazy(() =>
  import("./pages/NameCardCollection")
);
const LazyAddUserNameCard = React.lazy(() => import("./pages/AddUserNameCard"));
const LazyAnalytics = React.lazy(() => import("./pages/Analytic"));
const LazyDesign = React.lazy(() => import("./pages/Design"));

function TrackPageView() {
  const location = useLocation();
  const posthog = usePosthog(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });
  const isLocalhost =
    window.location.hostname === "localhost" && window.location.port === "3000";

  useEffect(() => {
    if (!isLocalhost) {
      posthog.capture("page_view", { page: location.pathname });
    }
  }, [posthog, location.pathname, isLocalhost]);
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Provider store={store}>
          <Router>
            <TrackPageView />
            <UnauthorizedDialog />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/super-admin/login" element={<LazySuperAdmin />} />
                <Route
                  path="/super-admin/"
                  element={<LazySuperAdminDashBoard />}
                />
                <Route path="/company/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route path="/callback" element={<Callback />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoutes>
                      <Home />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoutes>
                      <LazyAdmin />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="name-card-collection"
                  element={
                    <ProtectedRoutes>
                      <LazyNameCardCollection />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="add-user-name-card"
                  element={
                    <ProtectedRoutes>
                      <LazyAddUserNameCard />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="analytic"
                  element={
                    <ProtectedRoutes>
                      <LazyAnalytics />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="design"
                  element={
                    <ProtectedRoutes>
                      <LazyDesign />
                    </ProtectedRoutes>
                  }
                />
                {/* Catch-all route for 404 errors */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
          </Router>
        </Provider>
      </header>
    </div>
  );
}

export default App;
