import { useMemo } from "react";
import { format, eachDayOfInterval, endOfMonth } from "date-fns";

const useDataProcessing = (nestedData, year, month) => {
  const filterAndAggregateData = useMemo(() => {
    const counts = {};
    const data = nestedData.flat();

    data.forEach((item) => {
      const itemDate = new Date(item.timestamp);
      if (
        itemDate.getFullYear() === year &&
        itemDate.getMonth() + 1 === month
      ) {
        const dayLabel = format(itemDate, "dd/MM/yy");

        if (!counts[dayLabel]) {
          counts[dayLabel] = { Total: 0, Unique: 0 };
        }
        counts[dayLabel].Total += 1;
        if (item.isNew) {
          counts[dayLabel].Unique += 1;
        }
      }
    });

    const start = new Date(year, month - 1, 1);
    const end = endOfMonth(start);
    const allDays = eachDayOfInterval({ start, end }).map((day) =>
      format(day, "dd/MM/yy")
    );

    const fullMonthData = allDays.map((day) => ({
      day,
      Total: counts[day] ? counts[day].Total : 0,
      Unique: counts[day] ? counts[day].Unique : 0,
    }));
    return fullMonthData;
  }, [nestedData, year, month]);

  const calculateRegionDistribution = useMemo(() => {
    const flattenedData = nestedData.flat();
    const regionCounts = {};
    const totalEntries = flattenedData.length;

    flattenedData.forEach((entry) => {
      const region = entry.region || "Other"; // Handle null regions
      if (!regionCounts[region]) {
        regionCounts[region] = { count: 0, percentage: 0 };
      }
      regionCounts[region].count += 1;
    });

    for (const region in regionCounts) {
      regionCounts[region].percentage = (
        (regionCounts[region].count / totalEntries) *
        100
      ).toFixed(2);
    }

    const sortedRegionCounts = Object.entries(regionCounts)
      .sort((a, b) => b[1].count - a[1].count)
      .reduce((acc, [region, data]) => {
        acc[region] = data;
        return acc;
      }, {});

    return sortedRegionCounts;
  }, [nestedData]);

  return { filterAndAggregateData, calculateRegionDistribution };
};

export default useDataProcessing;
