import React, { useState, useEffect } from "react";
import styled, { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Box,
  Typography,
  Card,
  CardContent,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { GlobalColor, GlobalBorderRadius } from "../constant/Global";
import {
  BadgeIcon,
  EditNoteIcon,
  DeleteForeverIcon,
  AnalyticsIcon,
  MoreHorizIcon,
} from "../constant/Icons";
import EditDialog from "../dialogs/EditDialog";
import CreateUser from "./CreateUser";
import FormatDateTime from "../constant/FormatDateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  GlobalUserInfoFields,
  GlobalNameCardUserInfoFields,
} from "../constant/Global";
import UserThunks from "../../redux/thunks/userThunks";
import NameCardUserThunks from "../../redux/thunks/nameCard/userThunks";
import { GlobalUserTypes } from "../constant/Global";
import UserNameCardDialog from "../dialogs/UserNameCardDialog";
import DeleteConfirmDialog from "../dialogs/DeleteConfirmDialog";

import { DeleteButton, EditButton } from "../../styled/Button";
import {
  StyledAvatarSmall,
  BaseColumnWrapper,
  StyledTableCell,
  StyledAvatarRegular,
} from "../../styled/BaseStyles";
import useSortData from "../../hooks/useSortData";
import UserAnalyticsDialog from "../dialogs/UserAnalyticsDialog";
import NameCardStyleConfigThunks from "../../redux/thunks/nameCard/styleConfigThunks";
import NameCardCompanyConfigThunks from "../../redux/thunks/nameCard/companyConfigThunks";
import { NotiName, NotiAction } from "../constant/Notification";
import NotiDialog from "../dialogs/NotiDialog";
import { useMediaQuery } from "react-responsive";
import NameCardUserActions from "../../redux/actions/nameCard/userActions";
import UserActions from "../../redux/actions/userActions";
import { useUserType } from "../context/UserTypeContext";
import useStatusChangeNotifications from "../../hooks/useStatusChangeNotifications";
import ImportUser from "./ImportUser";
const ColorBox = styled.div`
  padding: 4px;
  max-width: 60px;
  background-color: ${(props) => props.color};
  border-radius: ${GlobalBorderRadius.small};
  box-shadow: 0 0 10px ${GlobalColor.shadow};
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
`;

const StyledCardHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  padding-bottom: 1rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    padding: 2rem;
    padding-top: 0;
  }
`;

const StyledCardTypography = styled(Typography)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: flex-start;
`;

const IconButtonWrapper = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 0;
    margin-top: -1rem;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InfoTable = () => {
  const { userType } = useUserType();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [manageUserNameCard, setManageUserNameCard] = useState(false);
  const [seletedManageUser, setSelectedManageUser] = useState(null);
  const [selectedUserAnalytics, setSelectedUserAnalytics] = useState(null);
  const [openUserAnalytics, setOpenUserAnalytics] = useState(false);
  const [openNotiDialog, setOpenNotiDialog] = useState(false);
  const [notiInfo, setNotiInfo] = useState({});

  const nameCards = useSelector((state) => state.nameCard.all);
  const users = useSelector((state) => state.user.all);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  const [anchorEl, setAnchorEl] = useState(null);
  const openMoreActions = Boolean(anchorEl);

  const dispatch = useDispatch();

  const tableHeaders =
    userType === GlobalUserTypes.user
      ? [
          ...GlobalUserInfoFields,
          { key: "view", label: "View Analytics" },
          { key: "manage", label: "Manage Name Cards" },
          { key: "edit", label: "Edit" },
          { key: "delete", label: "Delete" },
        ]
      : [
          { key: "profileImg", label: "Icon" },
          ...GlobalNameCardUserInfoFields,
          { key: "Name Card display", label: "Display" },
          { key: "edit", label: "Edit" },
          { key: "delete", label: "Delete" },
        ];

  const UserInfoFields =
    userType === GlobalUserTypes.user
      ? GlobalUserInfoFields
      : GlobalNameCardUserInfoFields;

  const { resetDeleteNameCardStatus } = NameCardUserActions;
  const { resetDeleteUserStatus } = UserActions;

  const statusConfigs = [
    {
      stateSlice: "user",
      statusKey: "deleteUserStatus",
      action: NotiAction.Delete,
      name: NotiName.User,
      resetAction: resetDeleteUserStatus,
    },
    {
      stateSlice: "nameCard",
      statusKey: "deleteNameCardStatus",
      action: NotiAction.Delete,
      name: NotiName.NameCard,
      resetAction: resetDeleteNameCardStatus,
    },
  ];

  const handleCloseNotiDialog = useStatusChangeNotifications(
    statusConfigs,
    setNotiInfo,
    setOpenNotiDialog
  );

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    handleCloseNotiDialog();
  };

  const actionItems = (user, userType, handlers) => [
    {
      condition: userType === GlobalUserTypes.nameCardUser,
      icon: <BadgeIcon />,
      text: "View",
      action: () => handlers.handleDisplay(user),
    },
    {
      condition: userType === GlobalUserTypes.user,
      icon: <AnalyticsIcon />,
      text: "View",
      action: () => handlers.handleViewAnalytics(user),
    },
    {
      condition: userType === GlobalUserTypes.user,
      icon: <BadgeIcon />,
      text: "Manage",
      action: () => handlers.handleManageNameCards(user),
    },
    {
      condition: true,
      icon: <EditNoteIcon />,
      text: "Edit",
      action: () => handlers.handleEdit(user),
    },
    {
      condition: true,
      icon: <DeleteForeverIcon />,
      text: "Delete",
      action: () => handlers.handleDelete(user.id),
    },
  ];

  const DisplayField = ({ value }) => {
    if (typeof value === "string" && value.startsWith("#")) {
      return <ColorBox color={value}> {value} </ColorBox>;
    } else {
      return <div>{value}</div>;
    }
  };
  const sortedUserData = useSortData(
    userType === GlobalUserTypes.user ? users : nameCards,
    order,
    orderBy
  );

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpenEdit(false);
    setSelectedUser(null);
  };

  const handleDelete = (useId) => {
    setUserIdToDelete(useId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    setOpenDeleteDialog(false);
    if (userType === GlobalUserTypes.user) {
      dispatch(UserThunks.deleteTarget(userIdToDelete));
    } else if (userType === GlobalUserTypes.nameCardUser) {
      dispatch(NameCardUserThunks.deleteTarget(userIdToDelete));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDisplay = (nameCardUser) => {
    const userId = nameCardUser.id;
    const baseFrontendUrl =
      process.env.REACT_APP_FRONTEND_URL || "http://localhost:3001";
    const url = new URL(userId, baseFrontendUrl).href;

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  const handleManageNameCards = (user) => {
    setManageUserNameCard(true);
    setSelectedManageUser(user);
  };

  const handleViewAnalytics = (user) => {
    setOpenUserAnalytics(true);
    setSelectedUserAnalytics(user);
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userType === GlobalUserTypes.nameCardUser) {
      dispatch(NameCardStyleConfigThunks.getAll());
      dispatch(NameCardCompanyConfigThunks.getAll());
    }
  }, [dispatch, userType]);

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <BaseColumnWrapper>
        <ActionWrapper>
          {GlobalUserTypes.user === userType && <ImportUser />}
          <CreateUser />
        </ActionWrapper>
        <TableContainer component={Paper}>
          {!isSmallScreen && (
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders
                    .filter(
                      (field) =>
                        field.key !== "password" &&
                        field.key !== "nameCardStyleConfigId" &&
                        field.key !== "nameCardCompanyConfigId"
                    )
                    .map((header) => (
                      <StyledTableCell key={header.key}>
                        <TableSortLabel
                          active={orderBy === header.key}
                          direction={orderBy === header.key ? order : "asc"}
                          onClick={createSortHandler(header.key)}
                        >
                          {header.label}
                        </TableSortLabel>
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUserData &&
                  sortedUserData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => (
                      <TableRow key={user.id}>
                        {userType === GlobalUserTypes.nameCardUser && (
                          <StyledTableCell>
                            <StyledAvatarSmall
                              src={user.profileImgPreSignedUrl}
                            />
                          </StyledTableCell>
                        )}
                        {UserInfoFields.filter(
                          (field) =>
                            field.key !== "password" &&
                            field.key !== "nameCardStyleConfigId" &&
                            field.key !== "nameCardCompanyConfigId"
                        ).map(({ key }) => (
                          <StyledTableCell key={key}>
                            {key === "updatedAt" || key === "createdAt" ? (
                              FormatDateTime(user[key])
                            ) : (
                              <DisplayField value={user[key]} />
                            )}
                          </StyledTableCell>
                        ))}
                        {actionItems(user, userType, {
                          handleDisplay,
                          handleViewAnalytics,
                          handleManageNameCards,
                          handleEdit,
                          handleDelete,
                        })
                          .filter((item) => item.condition)
                          .map((item, index) => (
                            <StyledTableCell key={index}>
                              {item.text === "Delete" ? (
                                <DeleteButton onClick={() => item.action(user)}>
                                  {item.icon}
                                  {item.text}
                                </DeleteButton>
                              ) : (
                                <EditButton onClick={() => item.action(user)}>
                                  {item.icon}
                                  {item.text}
                                </EditButton>
                              )}
                            </StyledTableCell>
                          ))}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          )}
          {isSmallScreen &&
            sortedUserData &&
            sortedUserData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <StyledCard key={user.id}>
                  <StyledCardHeader>
                    <StyledHeader>
                      {userType === GlobalUserTypes.nameCardUser && (
                        <StyledAvatarRegular
                          src={user.profileImgPreSignedUrl}
                          alt={user.name}
                          sx={{ width: 50, height: 50, marginRight: 2 }}
                        />
                      )}

                      <Box>
                        <StyledCardTypography variant="h6" component="div">
                          {userType === GlobalUserTypes.user && "User Name: "}
                          {user.name}
                        </StyledCardTypography>
                        {userType === GlobalUserTypes.user ? (
                          <StyledCardTypography
                            variant="body2"
                            color="text.secondary"
                          >
                            User Role: {user.userRole}
                          </StyledCardTypography>
                        ) : (
                          <StyledCardTypography
                            variant="body2"
                            color="text.secondary"
                          >
                            {user.position}
                          </StyledCardTypography>
                        )}
                      </Box>
                    </StyledHeader>
                    <div style={{ display: "flex", alignItems: "top" }}>
                      <IconButtonWrapper onClick={handleMoreClick}>
                        <MoreHorizIcon />
                      </IconButtonWrapper>
                    </div>
                  </StyledCardHeader>
                  <StyledCardContent>
                    <StyledCardTypography
                      variant="body2"
                      color="text.secondary"
                    >
                      Email: {user.email}
                    </StyledCardTypography>
                    {userType === GlobalUserTypes.user ? (
                      <StyledCardTypography
                        variant="body2"
                        color="text.secondary"
                      >
                        Auth Type: {user.authType}
                      </StyledCardTypography>
                    ) : (
                      <StyledCardTypography
                        variant="body2"
                        color="text.secondary"
                      >
                        Phone: {user.phone}
                      </StyledCardTypography>
                    )}
                    <StyledCardTypography
                      variant="body2"
                      color="text.secondary"
                    >
                      Remarks: {user.nameCardRemark}
                    </StyledCardTypography>
                  </StyledCardContent>
                  <Popover
                    open={openMoreActions}
                    anchorEl={anchorEl}
                    onClose={handleCloseMore}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <List>
                      {actionItems(user, userType, {
                        handleDisplay,
                        handleViewAnalytics,
                        handleManageNameCards,
                        handleEdit,
                        handleDelete,
                      })
                        .filter((item) => item.condition)
                        .map((item, index) => (
                          <ListItem
                            button
                            key={index}
                            onClick={() => {
                              item.action(user);
                              handleCloseMore();
                            }}
                          >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                          </ListItem>
                        ))}
                    </List>
                  </Popover>
                </StyledCard>
              ))}
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={sortedUserData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {selectedUser && (
            <EditDialog
              open={openEdit}
              handleClose={handleClose}
              editUser={selectedUser}
            />
          )}
          {manageUserNameCard && (
            <UserNameCardDialog
              openDialog={manageUserNameCard}
              targetUser={seletedManageUser}
              handleClose={() => setManageUserNameCard(false)}
              handleDisplay={handleDisplay}
            />
          )}
          {openUserAnalytics && (
            <UserAnalyticsDialog
              openDialog={openUserAnalytics}
              targetUser={selectedUserAnalytics}
              handleClose={() => setOpenUserAnalytics(false)}
            />
          )}
          <DeleteConfirmDialog
            openDialog={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            handleDelete={handleDeleteConfirm}
          />
          {openNotiDialog && (
            <NotiDialog
              openDialog={openNotiDialog}
              onClose={handleCloseDialog}
              notiInfo={notiInfo}
            />
          )}
        </TableContainer>
      </BaseColumnWrapper>
    </StyleSheetManager>
  );
};

export default InfoTable;
