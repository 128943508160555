import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { UploadIcon } from "../constant/Icons";
import { useDispatch } from "react-redux";
import NameCardUserThunks from "../../redux/thunks/nameCard/userThunks";
import UserThunks from "../../redux/thunks/userThunks";
import {
  GlobalNameCardUserInfoFields,
  GlobalUserInfoFields,
  GlobalUserTypes,
} from "../constant/Global";
import { useSelector } from "react-redux";
import { CancelButtonAlert, ConfirmButton } from "../../styled/Button";
import { StyledAvatar } from "../../styled/BaseStyles";
import { NotiName, NotiAction } from "../constant/Notification";
import NotiDialog from "./NotiDialog";
import NameCardUserActions from "../../redux/actions/nameCard/userActions";
import UserActions from "../../redux/actions/userActions";
import { useUserType } from "../context/UserTypeContext";
import useImageUploadWithFormState from "../../hooks/useImageUpload";
import useStatusChangeNotifications from "../../hooks/useStatusChangeNotifications";
import ExtraInfoList from "../constant/ExtraInfoList";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 20px;
  }
`;

const UploadIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  cursor: pointer;
`;

const StyledAvatarContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const CusSelect = styled(Select)`
  width: 100%;
  margin: 8px 0;
`;

const EditDialog = ({ open, handleClose, editUser }) => {
  const { userType } = useUserType();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(editUser || {});
  const handleImageChange = useImageUploadWithFormState(setFormValues);
  const roles = ["Admin", "General"];
  const [openNotiDialog, setOpenNotiDialog] = useState(false);
  const [notiInfo, setNotiInfo] = useState({});
  const styleConfigs = useSelector((state) => state.nameCardStyleConfig.all);
  const users = useSelector((state) => state.user.all);
  const [extraInfoItems, setExtraInfoItems] = useState(
    formValues.extraInfo || null
  );
  const companyConfigs = useSelector(
    (state) => state.nameCardCompanyConfig.all
  );

  const UserInfoFields =
    userType === GlobalUserTypes.user
      ? GlobalUserInfoFields
      : GlobalNameCardUserInfoFields;

  const { resetCreateNameCardStatus, resetUpdateNameCardStatus } =
    NameCardUserActions;
  const { resetCreateUserStatus, resetUpdateUserStatus } = UserActions;

  const statusConfigs = [
    {
      stateSlice: "user",
      statusKey: "createUserStatus",
      action: NotiAction.Create,
      name: NotiName.User,
      resetAction: resetCreateUserStatus,
    },
    {
      stateSlice: "user",
      statusKey: "updateUserStatus",
      action: NotiAction.Update,
      name: NotiName.User,
      resetAction: resetUpdateUserStatus,
    },
    {
      stateSlice: "nameCard",
      statusKey: "createNameCardStatus",
      action: NotiAction.Create,
      name: NotiName.NameCard,
      resetAction: resetCreateNameCardStatus,
    },
    {
      stateSlice: "nameCard",
      statusKey: "updateNameCardStatus",
      action: NotiAction.Update,
      name: NotiName.NameCard,
      resetAction: resetUpdateNameCardStatus,
    },
  ];

  const handleCloseNotiDialog = useStatusChangeNotifications(
    statusConfigs,
    setNotiInfo,
    setOpenNotiDialog
  );
  const handleCloseDialog = () => {
    handleClose();
    setFormValues({});
    setExtraInfoItems(null);
    handleCloseNotiDialog();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    for (const field of UserInfoFields) {
      if (field.required && !formValues[field.key]) {
        alert(`${field.label} is required.`);
        return false;
      }
    }

    // Check for valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formValues.email && !emailRegex.test(formValues.email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    if (editUser) {
      handleSave();
    } else {
      handleCreate();
    }
  };

  const handleSave = () => {
    if (userType === GlobalUserTypes.user) {
      dispatch(UserThunks.updateTarget(formValues));
    } else if (userType === GlobalUserTypes.nameCardUser) {
      dispatch(NameCardUserThunks.updateTarget(formValues));
    }
  };

  const handleCreate = () => {
    if (userType === GlobalUserTypes.nameCardUser) {
      dispatch(NameCardUserThunks.createTarget(formValues));
    } else if (userType === GlobalUserTypes.user) {
      if (users.find((user) => user.email === formValues.email)) {
        alert("Email already exists.");
        return;
      } else {
        dispatch(UserThunks.createTarget(formValues));
      }
    }
  };

  useEffect(() => {
    setOpenNotiDialog(false);
  }, [editUser]);

  useEffect(() => {
    if (open) {
      setOpenNotiDialog(false);
      setFormValues(editUser || {});
    }
  }, [open, editUser]);

  useEffect(() => {
    if (formValues) {
      setExtraInfoItems(formValues.extraInfo || null);
    }
  }, [formValues]);

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>{editUser ? "Edit Information" : "Create User"}</DialogTitle>
      <DialogContent>
        {userType === GlobalUserTypes.nameCardUser && (
          <div>
            {"Profile Image"}
            <UploadIconContainer>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="profile-image-upload"
                type="file"
                onChange={handleImageChange("profileImg")}
              />
              <label htmlFor="profile-image-upload">
                <StyledAvatarContainer>
                  <StyledAvatar src={formValues["profileImgPreSignedUrl"]}>
                    <UploadIcon fontSize="large" color="info" />
                  </StyledAvatar>
                </StyledAvatarContainer>
              </label>
            </UploadIconContainer>
          </div>
        )}
        {UserInfoFields.filter((field) => {
          if (!editUser) {
            if (field.key === "password" || field.key === "email") return true;
          }
          return (
            field.key !== "updatedAt" &&
            field.key !== "createdAt" &&
            field.key !== "password" &&
            (editUser || (field.key !== "id" && field.key !== "authType"))
          );
        }).map((field) => {
          if (field.key === "userRole") {
            return (
              <div key={field.key}>
                <label>{field.label}</label>
                <CusSelect
                  key={field.key}
                  name={field.key}
                  value={formValues[field.key] || "General"}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </CusSelect>
              </div>
            );
          } else if (field.key === "nameCardStyleConfigId") {
            return (
              <div key={field.key}>
                <label>{field.label}</label>
                <CusSelect
                  key={field.key}
                  name={field.key}
                  value={formValues[field.key] || ""}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                >
                  {Object.keys(styleConfigs).map((index) => (
                    <MenuItem key={index} value={styleConfigs[index].id}>
                      {styleConfigs[index].versionName}
                    </MenuItem>
                  ))}
                </CusSelect>
              </div>
            );
          } else if (field.key === "nameCardCompanyConfigId") {
            return (
              <div key={field.key}>
                <label>{field.label}</label>
                <CusSelect
                  key={field.key}
                  name={field.key}
                  value={formValues[field.key] || ""}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                >
                  {companyConfigs &&
                    Object.keys(companyConfigs).map((index) => (
                      <MenuItem key={index} value={companyConfigs[index].id}>
                        {companyConfigs[index].versionName}
                      </MenuItem>
                    ))}
                </CusSelect>
              </div>
            );
          } else {
            return (
              <div key={field.key}>
                <label>
                  {field.label}
                  {field.required ? " *" : ""}
                </label>
                <TextField
                  key={field.key}
                  name={field.key}
                  type={field.type}
                  value={formValues[field.key] || ""}
                  onChange={handleChange}
                  fullWidth
                  disabled={
                    userType === GlobalUserTypes.user &&
                    editUser &&
                    !field.isEditable
                      ? true
                      : false
                  }
                  autoFocus={field.autoFocus}
                  margin="dense"
                  required={field.required}
                />
              </div>
            );
          }
        })}
        {userType === GlobalUserTypes.nameCardUser && (
          <ExtraInfoList
            extraInfoItems={extraInfoItems}
            setExtraInfoItems={setExtraInfoItems}
            setFormValues={setFormValues}
          />
        )}
      </DialogContent>
      {openNotiDialog && (
        <NotiDialog
          openDialog={openNotiDialog}
          onClose={handleCloseDialog}
          notiInfo={notiInfo}
        />
      )}
      <DialogActions>
        <CancelButtonAlert onClick={handleCloseDialog}>
          Cancel
        </CancelButtonAlert>
        <ConfirmButton onClick={handleSubmit}>
          {editUser ? "Save" : "Create"}
        </ConfirmButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default EditDialog;
