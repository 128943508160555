import React from "react";
import Navbar from "./Navbar";
import HomeDrawer from "./HomeDrawer";
import {
  BaseContainer,
  BaseContainerColumn,
  BaseContainerDashboard,
} from "../../styled/BaseStyles";

const PageWrapper = ({ children }) => {
  return (
    <BaseContainer>
      <HomeDrawer />
      <BaseContainerColumn>
        <Navbar />
        <BaseContainerDashboard>{children}</BaseContainerDashboard>
      </BaseContainerColumn>
    </BaseContainer>
  );
};

export default PageWrapper;
