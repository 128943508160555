// nameCardUserReducer.js
import {
  GET_NAME_CARDS_SUCCESS,
  GET_NAME_CARDS_FAILURE,
  GET_TARGET_USER_NAME_CARDS_SUCCESS,
  GET_TARGET_USER_NAME_CARDS_FAILURE,
  CREATE_NAME_CARD_SUCCESS,
  CREATE_NAME_CARD_FAILURE,
  UPDATE_NAME_CARD_SUCCESS,
  UPDATE_NAME_CARD_FAILURE,
  DELETE_NAME_CARD_SUCCESS,
  DELETE_NAME_CARD_FAILURE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  RESET_CREATE_STATUS,
  RESET_UPDATE_STATUS,
  RESET_DELETE_STATUS,
} from "../../actions/nameCard/userActions";

const initialState = {
  all: [],
  target: [],
  files: [],
  count: 0,
  error: null,
  createNameCardStatus: null,
  updateNameCardStatus: null,
  deleteNameCardStatus: null,
};

const nameCardUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NAME_CARDS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        count: action.payload.length,
        error: null,
      };
    case GET_NAME_CARDS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TARGET_USER_NAME_CARDS_SUCCESS:
      return {
        ...state,
        target: action.payload,
        error: null,
      };
    case GET_TARGET_USER_NAME_CARDS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_NAME_CARD_SUCCESS:
      return {
        ...state,
        createNameCardStatus: "success",
        error: null,
      };
    case CREATE_NAME_CARD_FAILURE:
      return {
        ...state,
        createNameCardStatus: "fail",
        error: action.payload,
      };
    case UPDATE_NAME_CARD_SUCCESS:
      return {
        ...state,
        updateNameCardStatus: "success",
        error: null,
      };
    case UPDATE_NAME_CARD_FAILURE:
      return {
        ...state,
        updateNameCardStatus: "fail",
        error: action.payload,
      };
    case DELETE_NAME_CARD_SUCCESS:
      return {
        ...state,
        deleteNameCardStatus: "success",
        error: null,
      };
    case DELETE_NAME_CARD_FAILURE:
      return {
        ...state,
        deleteNameCardStatus: "fail",
        error: action.payload,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        files: action.payload,
        error: null,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_CREATE_STATUS:
      return {
        ...state,
        createNameCardStatus: null,
      };
    case RESET_UPDATE_STATUS:
      return {
        ...state,
        updateNameCardStatus: null,
      };
    case RESET_DELETE_STATUS:
      return {
        ...state,
        deleteNameCardStatus: null,
      };
    default:
      return state;
  }
};

export default nameCardUserReducer;
