import React from "react";
import { GlobalColor, menuItems } from "../constant/Global";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthThunks from "../../redux/thunks/authThunks";
import { LogoutIcon } from "../constant/Icons";

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${GlobalColor.white};
`;

const MenuItem = styled.div`
  padding: 1rem;
  margin-top: 2rem;
  color: ${GlobalColor.primary};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 1rem;
  &:hover {
    background-color: ${GlobalColor.lightGrey};
    cursor: pointer;
  }
  &.active {
    color: ${GlobalColor.white};
    background-color: ${GlobalColor.primary};
  }
`;

const NavbarMenu = ({ handleClose }) => {
  const nevigate = useNavigate();
  const user = useSelector((state) => state.user.target);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleOnClick = (path) => {
    nevigate(path);
    handleClose();
  };
  const handleLogout = () => {
    dispatch(AuthThunks.userLogout());
    handleClose();
  };
  return (
    <MenuWrapper>
      {menuItems
        .filter((item) => item.label !== "Design")
        .map(
          (item) =>
            (!item.role || (user && user.userRole === item.role)) && (
              <MenuItem
                key={item.path}
                onClick={() => {
                  handleOnClick(item.path);
                }}
                className={location.pathname === item.path ? "active" : ""}
              >
                {item.icon}
                {item.label}
              </MenuItem>
            )
        )}
      <MenuItem onClick={handleLogout}>
        <LogoutIcon />
        Logout
      </MenuItem>
    </MenuWrapper>
  );
};

export default NavbarMenu;
