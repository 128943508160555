export const GET_NAME_CARDS_SUCCESS = "GET_NAME_CARDS_SUCCESS";
export const GET_NAME_CARDS_FAILURE = "GET_NAME_CARDS_FAILURE";
export const GET_TARGET_USER_NAME_CARDS_SUCCESS =
  "GET_TARGET_USER_NAME_CARDS_SUCCESS";
export const GET_TARGET_USER_NAME_CARDS_FAILURE =
  "GET_TARGET_USER_NAME_CARDS_FAILURE";
export const CREATE_NAME_CARD_SUCCESS = "CREATE_NAME_CARD_SUCCESS";
export const CREATE_NAME_CARD_FAILURE = "CREATE_NAME_CARD_FAILURE";
export const UPDATE_NAME_CARD_SUCCESS = "UPDATE_NAME_CARD_SUCCESS";
export const UPDATE_NAME_CARD_FAILURE = "UPDATE_NAME_CARD_FAILURE";
export const DELETE_NAME_CARD_SUCCESS = "DELETE_NAME_CARD_SUCCESS";
export const DELETE_NAME_CARD_FAILURE = "DELETE_NAME_CARD_FAILURE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const RESET_CREATE_STATUS = "RESET_CREATE_STATUS";
export const RESET_UPDATE_STATUS = "RESET_UPDATE_STATUS";
export const RESET_DELETE_STATUS = "RESET_DELETE_STATUS";

const getAllSuccess = (data) => ({
  type: GET_NAME_CARDS_SUCCESS,
  payload: data,
});

const getAllFailure = (error) => ({
  type: GET_NAME_CARDS_FAILURE,
  payload: error,
});

const getTargetsSuccess = (data) => ({
  type: GET_TARGET_USER_NAME_CARDS_SUCCESS,
  payload: data,
});

const getTargetsFailure = (error) => ({
  type: GET_TARGET_USER_NAME_CARDS_FAILURE,
  payload: error,
});

const createTargetSuccess = (data) => ({
  type: CREATE_NAME_CARD_SUCCESS,
  payload: data,
});

const createTargetFailure = (error) => ({
  type: CREATE_NAME_CARD_FAILURE,
  payload: error,
});
const updateTargetSuccess = (data) => ({
  type: UPDATE_NAME_CARD_SUCCESS,
  payload: data,
});

const updateTargetFailure = (error) => ({
  type: UPDATE_NAME_CARD_FAILURE,
  payload: error,
});

const deleteTargetSuccess = (data) => ({
  type: DELETE_NAME_CARD_SUCCESS,
  payload: data,
});

const deleteTargetFailure = (error) => ({
  type: DELETE_NAME_CARD_FAILURE,
  payload: error,
});

const uploadTargetFileSuccess = (data) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: data,
});

const uploadTargetFileFailure = (error) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: error,
});

const resetCreateNameCardStatus = () => ({
  type: RESET_CREATE_STATUS,
});

const resetUpdateNameCardStatus = () => ({
  type: RESET_UPDATE_STATUS,
});

const resetDeleteNameCardStatus = () => ({
  type: RESET_DELETE_STATUS,
});

const NameCardUserActions = {
  getAllSuccess,
  getAllFailure,
  getTargetsSuccess,
  getTargetsFailure,
  createTargetSuccess,
  createTargetFailure,
  updateTargetSuccess,
  updateTargetFailure,
  deleteTargetSuccess,
  deleteTargetFailure,
  uploadTargetFileSuccess,
  uploadTargetFileFailure,
  resetCreateNameCardStatus,
  resetUpdateNameCardStatus,
  resetDeleteNameCardStatus,
};

export default NameCardUserActions;
