import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const adminRoutes = ["/admin", "/admin/dashboard", "/design"];

const ProtectedRoutes = ({ children }) => {
  const authState = useSelector((state) => state.auth);
  const { isAuthenticated } = authState;
  const user = useSelector((state) => state.user.target);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (user) {
    if (
      adminRoutes.includes(window.location.pathname) &&
      user.userRole !== "Admin"
    ) {
      return <Navigate to="/login" />;
    }
  }

  return children;
};

export default ProtectedRoutes;
