// pages/Login.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "../components/constant/Icons";
import { GlobalImageURLs } from "../components/constant/Global";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginButton } from "../styled/Button";
import AuthThunks from "../redux/thunks/authThunks";
import { useSelector } from "react-redux";
import {
  NotiType,
  NotiAction,
  NotiName,
  getNotiInformation,
} from "../components/constant/Notification";
import NotiDialog from "../components/dialogs/NotiDialog";

const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;

const FormContainer = styled(Box)`
  width: 100%;
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #ffffff;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 300px;
    border-radius: 0;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 200px;
`;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const userEmail = useSelector((state) => state.auth.reset.email);
  const resetStatus = useSelector((state) => state.auth.reset.status);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rePassword, setRePassword] = useState("");
  const [showRePassword, setShowRePassword] = useState(false);
  const navigate = useNavigate();
  const [openNotiDialog, setOpenNotiDialog] = useState(false);
  const [notiInfo, setNotiInfo] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== rePassword) {
      alert("Password and Re-Password are not matched");
      return;
    }
    await dispatch(
      AuthThunks.resetPassword({
        token,
        password,
      })
    );
  };

  const handleCloseNotiDialog = () => {
    setOpenNotiDialog(false);
    navigate("/login");
  };

  const handleBackToLoginPage = () => {
    navigate("/login");
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRePassword = () => {
    setShowRePassword(!showRePassword);
  };

  const maskEmail = (email) => {
    if (!email) return "";
    const [user, domain] = email.split("@");
    const maskedUser =
      user.length > 3 ? user.slice(0, 2) + "..." + user.slice(-1) : user;
    return `${maskedUser}@${domain}`;
  };

  useEffect(() => {
    if (token) {
      dispatch(AuthThunks.getResetEmail(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (resetStatus === "success") {
      setOpenNotiDialog(true);
      setNotiInfo(
        getNotiInformation(
          NotiAction.ResetPassword,
          NotiName.Password,
          NotiType.Success
        )
      );
    }
  }, [resetStatus]);
  return (
    <LoginContainer>
      <LogoWrapper>
        <Logo src={GlobalImageURLs.YoovLogoB} alt="YOOV Logo" />
        <Typography variant="h6">Reset Your Password</Typography>
      </LogoWrapper>
      <FormContainer mt={2}>
        <Typography variant="h6" fontWeight={"bold"} fontSize={"1rem"}>
          Change Password for Your Account:
          <p>{maskEmail(userEmail)}</p>
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              required
              autoComplete="on"
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel htmlFor="re-password">Re-Password</InputLabel>
            <OutlinedInput
              id="re-password"
              type={showRePassword ? "text" : "password"}
              value={rePassword}
              required
              autoComplete="on"
              onChange={(e) => setRePassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRePassword}
                    edge="end"
                  >
                    {showRePassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Re-Password"
            />
          </FormControl>
          <LoginButton type="submit">Change password</LoginButton>
        </form>
        <Button onClick={handleBackToLoginPage}>Back to Login Page</Button>
      </FormContainer>
      {openNotiDialog && (
        <NotiDialog
          openDialog={openNotiDialog}
          onClose={handleCloseNotiDialog}
          notiInfo={notiInfo}
        />
      )}
    </LoginContainer>
  );
};

export default ResetPassword;
