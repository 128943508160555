import {
  GridViewIcon,
  CollectionsBookmarkIcon,
  SupervisorAccountIcon,
  AnalyticsIcon,
  AutoFixHighIcon,
} from "../constant/Icons";

export const menuItems = [
  { path: "/", label: "Dashboard", icon: <GridViewIcon /> },
  {
    path: "/admin",
    label: "Manage Users",
    icon: <SupervisorAccountIcon />,
    role: "Admin",
  },
  {
    path: "/name-card-collection",
    label: "Collections",
    icon: <CollectionsBookmarkIcon />,
  },
  { path: "/analytic", label: "Analytics", icon: <AnalyticsIcon /> },
  {
    path: "/design",
    label: "Design",
    icon: <AutoFixHighIcon />,
    role: "Admin",
  },
];

export const GlobalFontWeight = {
  bold: "700",
  semiBold: "600",
  regular: "400",
  light: "300",
};

export const GlobalFontSize = {
  small: "12px",
  regular: "14px",
  medium: "16px",
  large: "24px",
  extraLarge: "32px",
};

export const GlobalColor = {
  primary: "#2f445c",
  white: "#ffffff",
  lightGrey: "#f9f9f9",
  shadow: "rgba(0, 0, 0, 0.1)",
  red: "#ff4944",
  green: "#00c851",
  blue: "#33b5e5",
  dark: "#000000",
  hover: "#555556",
};

export const GlobalBorderRadius = {
  small: "4px",
  medium: "8px",
  large: "16px",
  circle: "50%",
};

export const GlobalNameCardUserInfoFields = [
  {
    key: "email",
    label: "Email",
    type: "email",
    required: true,
  },
  {
    key: "name",
    label: "Name",
    type: "text",
    required: true,
    smallScreen: true,
  },
  {
    key: "position",
    label: "Position",
    type: "text",
    required: true,
    smallScreen: true,
  },
  { key: "phone", label: "Phone", type: "text", required: true },
  {
    key: "nameCardRemark",
    label: "Name Card Remark",
    type: "text",
    required: false,
    smallScreen: true,
  },
  { key: "nameCardStyleConfigId", label: "Style Config", type: "text" },
  { key: "nameCardCompanyConfigId", label: "Company Config", type: "text" },
];

export const GlobalUserInfoFields = [
  { key: "id", label: "User ID", type: "text", isEditable: false },
  // {
  //   key: "authType",
  //   label: "Auth Type",
  //   type: "text",
  //   required: false,
  //   default: "LOCAL",
  //   isEditable: false,
  // },
  {
    key: "userRole",
    label: "User Role",
    type: "text",
    required: false,
    default: "General",
    isEditable: true,
  },
  {
    key: "email",
    label: "Email",
    type: "email",
    required: true,
    isEditable: false,
  },
  {
    key: "password",
    label: "Password",
    type: "password",
    required: false,
    isEditable: false,
  },
  {
    key: "name",
    label: "Name",
    type: "text",
    required: false,
    isEditable: true,
  },
  {
    key: "position",
    label: "Position",
    type: "text",
    required: false,
    isEditable: true,
  },
  {
    key: "phone",
    label: "Phone",
    type: "text",
    required: false,
    isEditable: true,
  },
];

export const GlobalImageURLs = {
  YoovLogoB: "/images/yoov-logo-b.png",
  YoovLogoW: "/images/yoov-logo-w.png",
};

export const GlobalUserTypes = {
  user: "User",
  nameCardUser: "NameCardUser",
};

export const GlobalProfileFields = [
  { key: "id", label: "User ID", type: "text", isEditable: false },
  { key: "name", label: "Name", type: "text", isEditable: true },
  { key: "email", label: "Email", type: "email", isEditable: false },
  { key: "phone", label: "Phone", type: "text", isEditable: true },
  { key: "userRole", label: "User Role", type: "text", isEditable: false },
  { key: "authType", label: "Auth Type", type: "text", isEditable: false },
];

export const GlobalNameCardCollectionFields = [
  {
    key: "name",
    label: "Name",
    type: "text",
  },
  {
    key: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    key: "position",
    label: "Position",
    type: "text",
  },
  {
    key: "email",
    label: "Email",
    type: "email",
  },
  {
    key: "phone",
    label: "Phone",
    type: "text",
  },
  {
    key: "createdAt",
    label: "Added At",
    type: "text",
  },
  { key: "remarks", label: "Remarks", type: "text" },
];

export const StyleConfigFieldLabels = {
  versionName: {
    title: "Version Name",
    name: { title: "Name", label: "name" },
  },
  logoConfig: {
    title: "Company Logo",
    width: { title: "Width", label: "width" },
    height: { title: "Height", label: "height" },
    borderRadius: { title: "Border Radius", label: "borderRadius" },
  },
  profileConfig: {
    title: "Profile Icon",
    width: { title: "Width", label: "width" },
    height: { title: "Height", label: "height" },
    borderRadius: { title: "Border Radius", label: "borderRadius" },
  },
  nameConfig: {
    title: "Name",
    fontSize: { title: "Font Size", label: "fontSize" },
    fontWeight: { title: "Font Weight", label: "fontWeight" },
    fontColor: { title: "Font Color", label: "fontColor" },
  },
  contentConfig: {
    title: "Content",
    fontSize: { title: "Font Size", label: "fontSize" },
    fontWeight: { title: "Font Weight", label: "fontWeight" },
    fontColor: { title: "Font Color", label: "fontColor" },
  },
  buttonConfig: {
    title: "Button",
    fontSize: { title: "Font Size", label: "fontSize" },
    fontWeight: { title: "Font Weight", label: "fontWeight" },
    fontColor: { title: "Font Color", label: "fontColor" },
    color: { title: "Button Color", label: "color" },
    hoverColor: { title: "Button Hover Color", label: "hoverColor" },
  },
};

export const DefaultConfigValues = {
  logoConfig: {
    width: "50%",
    height: "auto",
    borderRadius: "0",
  },
  profileConfig: {
    width: "160px",
    height: "160px",
    borderRadius: "50%",
  },
  nameConfig: {
    fontSize: "1.6rem",
    fontColor: "#000000",
    fontWeight: "Bold",
  },
  contentConfig: {
    fontSize: "1rem",
    fontColor: "#000000",
    fontWeight: "400",
  },
  buttonConfig: {
    color: "#2f445c",
    fontSize: "1rem",
    fontColor: "#ffffff",
    fontWeight: "Bold",
    hoverColor: "#555556",
  },
};

export const nameCardCompanyConfigFields = [
  { key: "versionName", label: "Version Name", type: "text", isEditable: true },
  { key: "companyName", label: "Company Name", type: "text", isEditable: true },
  {
    key: "companyImgPreSignedUrl",
    label: "Company Logo",
    type: "text",
    isEditable: true,
  },
  {
    key: "primaryColor",
    label: "Primary Color",
    type: "text",
    isEditable: true,
  },
  {
    key: "secondaryColor",
    label: "Secondary Color",
    type: "text",
    isEditable: true,
  },
  {
    key: "companyPhone",
    label: "Company Phone",
    type: "text",
    isEditable: true,
  },
  {
    key: "companyAddress",
    label: "Company Address",
    type: "text",
    isEditable: true,
  },
  {
    key: "companyWebsite",
    label: "Company Website",
    type: "text",
    isEditable: true,
  },
];

export const DefaultNameCardCompanyConfig = {
  versionName: "Default",
  companyName: "",
  companyImg: "",
  primaryColor: "",
  secondaryColor: "",
};

export const NameCardExampleData = {
  companyImg:
    "https://yoov-name-card-demo-files-dev.s3.ap-east-1.amazonaws.com/YOOV-Logo-C.png",
  profileImg:
    "https://yoov-name-card-demo-files-dev.s3.ap-east-1.amazonaws.com/CarlChan.png",
  name: "John Doe",
  position: "Software Engineer",
  companyName: "Example Inc.",
  email: "testing@example.com",
  phone: "+1234567890",
  primaryColor: "#336699",
  secondaryColor: "#FFFFFF",
  companyImgPreSignedUrl:
    "https://marketing.yoov.com/vCARD/assets/YOOV-Logo-C.png",
  profileImgPreSignedUrl:
    "https://ncfile.yoov.plus/dc/64069b83183a32198ec91c14/f631503b-43fd-46dc-830e-6c38f5c86400.png?imageMogr2/ignore-error/1/format/webp",
};

export const PricePlanOptions = {
  Free: {
    nameCards: 10,
    description: "10 Name Cards for Each User",
  },
  Basic: {
    nameCards: 20,
    description: "20 Name Cards for Each User",
  },
  Pro: {
    nameCards: 50,
    description: "50 Name Cards for Each User",
  },
};

export const GlobalCompanyConfigFields = [
  { key: "id", label: "Id" },
  { key: "name", label: "Company Name" },
  { key: "email", label: "Company Email" },
  { key: "pricePlan", label: "Price Plan" },
  { key: "userLimit", label: "User Limit" },
  { key: "createdAt", label: "Created At" },
];
