// action.js
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_WITH_YOOV_SUCCESS = "LOGIN_WITH_YOOV_SUCCESS";
export const LOGIN_WITH_YOOV_FAILURE = "LOGIN_WITH_YOOV_FAILURE";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const GET_RESET_EMAIL_SUCCESS = "GET_RESET_EMAIL_SUCCESS";
export const GET_RESET_EMAIL_FAILURE = "GET_RESET_EMAIL_FAILURE";

const loginSuccess = (tokens) => ({
  type: LOGIN_SUCCESS,
  payload: tokens,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

const loginWithYoovSuccess = (tokens) => ({
  type: LOGIN_WITH_YOOV_SUCCESS,
  payload: tokens,
});

const loginWithYoovFailure = (error) => ({
  type: LOGIN_WITH_YOOV_FAILURE,
  payload: error,
});

const logout = () => ({
  type: LOGOUT,
});

const registerSuccess = () => ({
  type: REGISTER_SUCCESS,
});

const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});

const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: true,
});

const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

const getResetEmailSuccess = (email) => ({
  type: GET_RESET_EMAIL_SUCCESS,
  payload: email,
});

const getResetEmailFailure = (error) => ({
  type: GET_RESET_EMAIL_FAILURE,
  payload: error,
});

const AuthActions = {
  loginSuccess,
  loginFailure,
  loginWithYoovSuccess,
  loginWithYoovFailure,
  logout,
  registerSuccess,
  registerFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  getResetEmailSuccess,
  getResetEmailFailure,
};

export default AuthActions;
