export const GET_TARGET_NAME_CARD_ANALYTICS_SUCCESS =
  "GET_TARGET_NAME_CARD_ANALYTICS_SUCCESS";
export const GET_TARGET_NAME_CARD_ANALYTICS_FAILURE =
  "GET_TARGET_NAME_CARD_ANALYTICS_FAILURE";
export const GET_ALL_MY_NAME_CARD_ANALYTICS_SUCCESS =
  "GET_ALL_MY_NAME_CARD_ANALYTICS_SUCCESS";
export const GET_ALL_MY_NAME_CARD_ANALYTICS_FAILURE =
  "GET_ALL_MY_NAME_CARD_ANALYTICS_FAILURE";

const getTargetsSuccess = (data) => ({
  type: GET_TARGET_NAME_CARD_ANALYTICS_SUCCESS,
  payload: data,
});

const getTargetsFailure = (error) => ({
  type: GET_TARGET_NAME_CARD_ANALYTICS_FAILURE,
  payload: error,
});

const getAllSuccess = (data) => ({
  type: GET_ALL_MY_NAME_CARD_ANALYTICS_SUCCESS,
  payload: data,
});

const getAllFailure = (error) => ({
  type: GET_ALL_MY_NAME_CARD_ANALYTICS_FAILURE,
  payload: error,
});

const NameCardAnalyticsActions = {
  getTargetsSuccess,
  getTargetsFailure,
  getAllSuccess,
  getAllFailure,
};

export default NameCardAnalyticsActions;
