import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import useDataProcessing from "../../hooks/useDataProcessing";
import {
  ComponentWrapper,
  BaseWrapper,
  NumberWrapper,
  HeaderRowWrapper,
} from "../../styled/BaseStyles";
import { RegularTextBoxWrapper } from "../../styled/Text";
import { GlobalColor } from "../constant/Global";
import { useSelector } from "react-redux";
import ViewerRegion from "./ViewerRegion";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const DateWrapper = styled(BaseWrapper)`
  @media (max-width: 600px) {
    flex-direction: row;
  }
`;

const NameCardViewChart = (flattenedData) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedNameCard, setSelectedNameCard] = useState("total");
  const nameCards = useSelector((state) => state.nameCard.target);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  const selectedData =
    selectedNameCard === "total"
      ? flattenedData.data
      : flattenedData.data.filter(
          (data) => data.nameCardId === selectedNameCard
        );

  const { filterAndAggregateData } = useDataProcessing(
    selectedData,
    selectedYear,
    selectedMonth
  );

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value));
  };

  const handleSelectNameCardChange = (e) => {
    setSelectedNameCard(e.target.value);
  };

  return (
    <div>
      <ComponentWrapper>
        <BaseWrapper>
          <HeaderRowWrapper>
            <RegularTextBoxWrapper>
              Name Card Views for{" "}
              {new Date(selectedYear, selectedMonth - 1).toLocaleString(
                "default",
                {
                  month: "long",
                }
              )}{" "}
              {selectedYear}
            </RegularTextBoxWrapper>
            <DateWrapper>
              <FormControl variant="outlined" style={{ marginRight: "10px" }}>
                <InputLabel>Year</InputLabel>
                <Select
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                  sx={{ width: `${isSmallScreen ? "90px" : "100px"}` }}
                >
                  {[...Array(5)].map((_, i) => (
                    <MenuItem key={currentYear - i} value={currentYear - i}>
                      {currentYear - i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel>Month</InputLabel>
                <Select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  label="Month"
                  sx={{ width: `${isSmallScreen ? "90px" : "100px"}` }}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                    <MenuItem key={month} value={month}>
                      {month.toString().padStart(2, "0")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DateWrapper>
          </HeaderRowWrapper>
          <HeaderRowWrapper>
            <RegularTextBoxWrapper>Choose Name Card</RegularTextBoxWrapper>
            <BaseWrapper>
              <Select
                value={selectedNameCard}
                onChange={handleSelectNameCardChange}
                sx={{ width: "200px" }}
              >
                <MenuItem value="total">Total Name Card Views</MenuItem>
                {nameCards &&
                  nameCards.map((nameCard) => (
                    <MenuItem key={nameCard} value={nameCard.id}>
                      {nameCard.nameCardRemark}
                    </MenuItem>
                  ))}
              </Select>

              <RegularTextBoxWrapper>
                Views: <NumberWrapper> {selectedData.length}</NumberWrapper>
              </RegularTextBoxWrapper>
            </BaseWrapper>
          </HeaderRowWrapper>
        </BaseWrapper>
        {!isSmallScreen && (
          <ResponsiveContainer width="100%" height={500}>
            <LineChart
              data={filterAndAggregateData}
              margin={{ top: 50, right: 100, left: 30, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="day" tick={{ fontSize: 20 }} />
              <YAxis tick={{ fontSize: 20 }} />
              <Tooltip contentStyle={{ fontSize: 20 }} />
              <Legend wrapperStyle={{ fontSize: 20 }} />
              <Line
                type="monotone"
                dataKey="Total"
                stroke={GlobalColor.dark}
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="Unique"
                stroke={GlobalColor.green}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </ComponentWrapper>
      <ViewerRegion data={selectedData} />
    </div>
  );
};

export default NameCardViewChart;
