import styled from "styled-components";
import { BaseTextWrapper, TextBoxWrapper } from "./BaseStyles";

export const ViewCountTextWrapper = styled(BaseTextWrapper)`
  padding: 0;
  padding-bottom: 1rem;
`;

export const SmallTextBoxWrapper = styled(TextBoxWrapper)`
  width: 200px;
  @media (max-width: 600px) {
    width: 150px;
  }
`;

export const RegularTextBoxWrapper = styled(TextBoxWrapper)`
  width: 400px;
  @media (max-width: 600px) {
    width: 300px;
    display: flex;
    flex-direction: row;
  }
`;
