import React from "react";
import {
  ComponentWrapper,
  HeaderWraper,
  FlexStartTitle,
  TextBoxWrapper,
  BaseTextWrapper,
} from "../../styled/BaseStyles";
import useDataProcessing from "../../hooks/useDataProcessing";
import styled from "styled-components";

const RegionDataWrapper = styled(BaseTextWrapper)`
  flex-wrap: wrap;
  margin: 0 4rem;
  padding: 0;
  padding-bottom: 1rem;
  gap: 4rem;
`;

const ViewerRegion = (data) => {
  const { calculateRegionDistribution } = useDataProcessing(data.data);
  return (
    <ComponentWrapper>
      <HeaderWraper>
        <FlexStartTitle>Viewer Regions</FlexStartTitle>
      </HeaderWraper>
      <RegionDataWrapper>
        {Object.keys(calculateRegionDistribution).length > 0 ? (
          Object.keys(calculateRegionDistribution).map((region) => (
            <TextBoxWrapper key={region}>
              {region} : {calculateRegionDistribution[region].count} (
              {calculateRegionDistribution[region].percentage}%)
            </TextBoxWrapper>
          ))
        ) : (
          <TextBoxWrapper>No data available</TextBoxWrapper>
        )}
      </RegionDataWrapper>
    </ComponentWrapper>
  );
};

export default ViewerRegion;
