import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CloseIcon } from "../constant/Icons";
import { CancelButton } from "../../styled/Button";
import AnalysticDashboard from "../analytics/AnalyticsDashboard";
import styled from "styled-components";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import NameCardAnalyticsThunks from "../../redux/thunks/nameCard/analyticsThunks";
import NameCardUserThunks from "../../redux/thunks/nameCard/userThunks";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 20px;
    width: 96%;
    max-width: 1400px;
  }
`;

const UserAnalyticsDialog = ({ openDialog, targetUser, handleClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (targetUser) {
      dispatch(NameCardAnalyticsThunks.getTargets(targetUser.id));
      dispatch(NameCardUserThunks.getTargets(targetUser.id));
    }
  }, [dispatch, targetUser]);

  return (
    <StyledDialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>View {targetUser.name}'s Analytics</DialogTitle>
      <DialogContent>
        <AnalysticDashboard />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose}>
          <CloseIcon />
          Close
        </CancelButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default UserAnalyticsDialog;
