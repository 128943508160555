import axiosApi from "../utils/axiosApi";
import { LOGIN_SUCCESS, LOGOUT } from "../redux/actions/authActions";

const authMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      axiosApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.accessToken}`;
      break;
    case LOGOUT:
      delete axiosApi.defaults.headers.common["Authorization"];
      break;
    default:
      break;
  }
  return next(action);
};

export default authMiddleware;
