import {
  GET_TARGET_NAME_CARD_STYLE_CONFIG_SUCCESS,
  GET_TARGET_NAME_CARD_STYLE_CONFIG_FAILURE,
  GET_MY_NAME_CARD_STYLE_CONFIGS_SUCCESS,
  GET_MY_NAME_CARD_STYLE_CONFIGS_FAILURE,
  CREATE_NAME_CARD_STYLE_CONFIG_SUCCESS,
  CREATE_NAME_CARD_STYLE_CONFIG_FAILURE,
  UPDATE_NAME_CARD_STYLE_CONFIG_SUCCESS,
  UPDATE_NAME_CARD_STYLE_CONFIG_FAILURE,
  DELETE_NAME_CARD_STYLE_CONFIG_SUCCESS,
  DELETE_NAME_CARD_STYLE_CONFIG_FAILURE,
  SET_GLOBAL_NAME_CARD_STYLE_CONFIG_SUCCESS,
  SET_GLOBAL_NAME_CARD_STYLE_CONFIG_FAILURE,
  RESET_CREATE_STYLE_CONFIG_STATUS,
  RESET_UPDATE_STYLE_CONFIG_STATUS,
  RESET_DELETE_STYLE_CONFIG_STATUS,
  RESET_SET_GLOBAL_STYLE_CONFIG_STATUS,
} from "../../actions/nameCard/styleConfigActions";

const initialState = {
  all: [],
  target: {},
  error: null,
  createStyleConfigStatus: null,
  updateStyleConfigStatus: null,
  deleteStyleConfigStatus: null,
  setGlobalStyleConfigStatus: null,
};

const nameCardStyleConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_NAME_CARD_STYLE_CONFIG_SUCCESS:
      return {
        ...state,
        target: action.payload,
        error: null,
      };
    case GET_TARGET_NAME_CARD_STYLE_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_MY_NAME_CARD_STYLE_CONFIGS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        error: null,
      };
    case GET_MY_NAME_CARD_STYLE_CONFIGS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_NAME_CARD_STYLE_CONFIG_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.payload],
        createStyleConfigStatus: "success",
        error: null,
      };
    case CREATE_NAME_CARD_STYLE_CONFIG_FAILURE:
      return {
        ...state,
        createStyleConfigStatus: "failure",
        error: action.payload,
      };
    case UPDATE_NAME_CARD_STYLE_CONFIG_SUCCESS:
      return {
        ...state,
        all: state.all.map((config) =>
          config.id === action.payload.id ? action.payload : config
        ),
        updateStyleConfigStatus: "success",
        error: null,
      };
    case UPDATE_NAME_CARD_STYLE_CONFIG_FAILURE:
      return {
        ...state,
        updateStyleConfigStatus: "failure",
        error: action.payload,
      };
    case DELETE_NAME_CARD_STYLE_CONFIG_SUCCESS:
      return {
        ...state,
        all: state.all.filter((config) => config.id !== action.payload),
        deleteStyleConfigStatus: "success",
        error: null,
      };
    case DELETE_NAME_CARD_STYLE_CONFIG_FAILURE:
      return {
        ...state,
        deleteStyleConfigStatus: "failure",
        error: action.payload,
      };
    case SET_GLOBAL_NAME_CARD_STYLE_CONFIG_SUCCESS:
      return {
        ...state,
        target: action.payload,
        setGlobalStyleConfigStatus: "success",
        error: null,
      };
    case SET_GLOBAL_NAME_CARD_STYLE_CONFIG_FAILURE:
      return {
        ...state,
        setGlobalStyleConfigStatus: "failure",
        error: action.payload,
      };
    case RESET_CREATE_STYLE_CONFIG_STATUS:
      return {
        ...state,
        createStyleConfigStatus: null,
      };

    case RESET_UPDATE_STYLE_CONFIG_STATUS:
      return {
        ...state,
        updateStyleConfigStatus: null,
      };

    case RESET_DELETE_STYLE_CONFIG_STATUS:
      return {
        ...state,
        deleteStyleConfigStatus: null,
      };

    case RESET_SET_GLOBAL_STYLE_CONFIG_STATUS:
      return {
        ...state,
        setGlobalStyleConfigStatus: null,
      };
    default:
      return state;
  }
};

export default nameCardStyleConfigReducer;
