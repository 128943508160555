class CustomError extends Error {
  constructor(message = "Internal server error", status = 500) {
    super(message);
    this.status = status;
  }
}

class ErrorOnFetch extends CustomError {
  constructor(details = "-") {
    super("Error on fetching: " + details, 402);
  }
}

class ErrorOnCreate extends CustomError {
  constructor(details = "-") {
    super("Error on creating: " + details, 403);
  }
}

class ErrorOnUpdate extends CustomError {
  constructor(details = "-") {
    super("Error on updating: " + details, 405);
  }
}

class ErrorOnDelete extends CustomError {
  constructor(details = "-") {
    super("Error on deleting: " + details, 406);
  }
}
class ErrorOnUpload extends CustomError {
  constructor(details = "-") {
    super("Error on uploading: " + details, 407);
  }
}
class AuthError extends CustomError {
  constructor(details = "-") {
    super("Unauthenticated: " + details, 401);
  }
}
class ErrorOnLogin extends CustomError {
  constructor(details = "-") {
    super("Error on login: " + details, 401);
  }
}
class ErrorOnRegister extends CustomError {
  constructor(details = "-") {
    super("Error on register: " + details, 401);
  }
}

export {
  CustomError,
  ErrorOnFetch,
  ErrorOnCreate,
  ErrorOnUpdate,
  ErrorOnDelete,
  ErrorOnUpload,
  AuthError,
  ErrorOnLogin,
  ErrorOnRegister,
};
