import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { CloseIcon, CheckIcon } from "../constant/Icons";
import { CancelButtonAlert, ConfirmButton } from "../../styled/Button";

const NotiDialog = ({ openDialog, onClose, notiInfo }) => {
  const [copyMessage, setCopyMessage] = useState("");

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopyMessage("Copied to clipboard successfully!");
        setTimeout(() => setCopyMessage(""), 3000); // Clear the message after 3 seconds
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <Dialog open={openDialog}>
      <DialogTitle>{notiInfo.title}</DialogTitle>
      <DialogContent>{notiInfo.message}</DialogContent>
      {notiInfo.extra && (
        <DialogContent
          onClick={() => handleCopyToClipboard(notiInfo.extra)}
          style={{ cursor: "pointer" }}
        >
          <strong>{notiInfo.extra}</strong>
        </DialogContent>
      )}
      {copyMessage && (
        <Typography
          variant="body2"
          color="success"
          style={{ marginTop: "10px", textAlign: "center" }}
        >
          {copyMessage}
        </Typography>
      )}
      <DialogActions>
        {notiInfo.status === ("Error" || "Warning" || "Not Found") ? (
          <CancelButtonAlert onClick={onClose}>
            <CloseIcon />
            Close
          </CancelButtonAlert>
        ) : (
          <ConfirmButton onClick={onClose}>
            <CheckIcon />
            Close
          </ConfirmButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NotiDialog;
