import axiosApi from "../../../utils/axiosApi";
import NameCardAnalyticsActions from "../../actions/nameCard/analyticsActions";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { errorMessages } from "../../../error/errorMessages";

const apiURL = "name-card/activity";

const getTargets = (targetUserId) => async (dispatch) => {
  try {
    const response = await axiosApi.get(apiURL + "/" + targetUserId);
    dispatch(NameCardAnalyticsActions.getTargetsSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardAnalyticsActions.getTargetsFailure(
        errorMessage || errorMessages.nameCardActivity.fetchFailed
      )
    );
  }
};

const getAll = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(apiURL);
    dispatch(NameCardAnalyticsActions.getAllSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardAnalyticsActions.getAllFailure(
        errorMessage || errorMessages.nameCardActivity.fetchAllFailed
      )
    );
  }
};

const NameCardAnalyticsThunks = {
  getTargets,
  getAll,
};

export default NameCardAnalyticsThunks;
