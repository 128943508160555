import styled from "styled-components";
import { GlobalColor, GlobalFontSize } from "../components/constant/Global";
import { BaseButton } from "./BaseStyles";

export const DeleteButton = styled(BaseButton)`
  background-color: ${GlobalColor.red};
`;

export const EditButton = styled(BaseButton)``;

export const ConfirmButton = styled(BaseButton)`
  background-color: ${GlobalColor.green};
`;

export const CancelButton = styled(BaseButton)`
  background-color: ${GlobalColor.green};
`;

export const CancelButtonAlert = styled(BaseButton)`
  background-color: ${GlobalColor.red};
`;

export const CreateButton = styled(BaseButton)`
  background-color: ${GlobalColor.green};
  font-size: ${GlobalFontSize.medium};
`;

export const ImportButton = styled(BaseButton)`
  font-size: ${GlobalFontSize.medium};
`;

export const LoginButton = styled(BaseButton)`
  background-color: ${GlobalColor.primary};
  font-size: ${GlobalFontSize.medium};
  width: 100%;
  margin-top: 2rem;
`;

export const NavbarButton = styled(BaseButton)`
  background-color: transparent;
  color: ${GlobalColor.primary};
  font-size: ${GlobalFontSize.medium};
  &:hover {
    background-color: ${GlobalColor.white};
  }
`;
