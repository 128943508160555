import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import UserActions from "../../redux/actions/userActions";
import { BaseButton } from "../../styled/BaseStyles";

const UnauthorizedDialog = () => {
  const open = useSelector((state) => state.user.unauthorizedDialogOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(UserActions.hideUnauthorizedDialog());
    window.location.href = "/login";
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Unauthorized</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Session expired or unauthorized user. Please login again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BaseButton onClick={handleClose} color="primary">
          Login Again
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
};

export default UnauthorizedDialog;
