// userReducer.js
import {
  GET_TARGET_SUCCESS,
  GET_TARGET_FAILURE,
  CHECK_ADMIN_RIGHT,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  CTEATE_USER_SUCCESS,
  CTEATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SHOW_UNAUTHORIZED_DIALOG,
  HIDE_UNAUTHORIZED_DIALOG,
  IMPORT_USERS_SUCCESS,
  IMPORT_USERS_FAILURE,
  DOWNLOAD_TEMPLATE_FAILURE,
  RESET_CREATE_STATUS,
  RESET_UPDATE_STATUS,
  RESET_DELETE_STATUS,
  RESET_IMPORT_USERS_STATUS,
} from "../actions/userActions";

const initialState = {
  target: null,
  all: [],
  error: null,
  count: 0,
  unauthorizedDialogOpen: false,
  createUserStatus: null,
  updateUserStatus: null,
  deleteUserStatus: null,
  importUsersStatus: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_SUCCESS:
      return {
        ...state,
        target: action.payload,
        error: null,
      };
    case GET_TARGET_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CHECK_ADMIN_RIGHT:
      return {
        ...state,
        isAdmin: action.payload === "admin",
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        count: action.payload.length,
        error: null,
      };
    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CTEATE_USER_SUCCESS:
      return {
        ...state,
        createUserStatus: "success",
        error: null,
      };
    case CTEATE_USER_FAILURE:
      return {
        ...state,
        createUserStatus: "fail",
        error: action.payload,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserStatus: "success",
        error: null,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserStatus: "fail",
        error: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserStatus: "success",
        error: null,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteUserStatus: "fail",
        error: action.payload,
      };
    case SHOW_UNAUTHORIZED_DIALOG:
      return {
        ...state,
        unauthorizedDialogOpen: true,
      };
    case HIDE_UNAUTHORIZED_DIALOG:
      return {
        ...state,
        unauthorizedDialogOpen: false,
      };
    case IMPORT_USERS_SUCCESS:
      return {
        ...state,
        importUsersStatus: "success",
        error: null,
      };
    case IMPORT_USERS_FAILURE:
      return {
        ...state,
        importUsersStatus: "fail",
        error: action.payload,
      };
    case DOWNLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_CREATE_STATUS:
      return {
        ...state,
        createUserStatus: null,
      };
    case RESET_UPDATE_STATUS:
      return {
        ...state,
        updateUserStatus: null,
      };
    case RESET_DELETE_STATUS:
      return {
        ...state,
        deleteUserStatus: null,
      };
    case RESET_IMPORT_USERS_STATUS:
      return {
        ...state,
        importUsersStatus: null,
      };
    default:
      return state;
  }
};

export default userReducer;
