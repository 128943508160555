import {
  GET_TARGET_NAME_CARD_ANALYTICS_SUCCESS,
  GET_TARGET_NAME_CARD_ANALYTICS_FAILURE,
  GET_ALL_MY_NAME_CARD_ANALYTICS_SUCCESS,
  GET_ALL_MY_NAME_CARD_ANALYTICS_FAILURE,
} from "../../actions/nameCard/analyticsActions";

const initialState = {
  target: [],
  all: [],
  error: null,
};

const nameCardAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_NAME_CARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        target: action.payload,
        error: null,
      };
    case GET_TARGET_NAME_CARD_ANALYTICS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_MY_NAME_CARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        error: null,
      };
    case GET_ALL_MY_NAME_CARD_ANALYTICS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default nameCardAnalyticsReducer;
