import { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector, createStructuredSelector } from "reselect";
import { getNotiInformation } from "../components/constant/Notification";

// Create a selector for a specific status key from a dynamic state slice
const makeSelectStatusByKey = (stateSliceKey, statusKey) =>
  createSelector(
    (state) => state[stateSliceKey],
    (stateSlice) => (stateSlice ? stateSlice[statusKey] : null)
  );

// Create a structured selector dynamically based on the provided status configurations
const createStatusSelector = (statusConfigs) =>
  createStructuredSelector(
    statusConfigs.reduce((acc, config) => {
      acc[config.statusKey] = makeSelectStatusByKey(
        config.stateSlice,
        config.statusKey
      );
      return acc;
    }, {})
  );

const useStatusChangeNotifications = (
  statusConfigs,
  setNotiInfo,
  setOpenNotiDialog
) => {
  const dispatch = useDispatch();
  const previousStatusesRef = useRef({});

  const statuses = useSelector(createStatusSelector(statusConfigs));
  const handleStatusChange = useCallback(
    (status, action, name) => {
      switch (status) {
        case "success":
          setNotiInfo(getNotiInformation(action, name, "Success"));
          setOpenNotiDialog(true);
          break;
        case "fail":
          setNotiInfo(getNotiInformation(action, name, "Error"));
          setOpenNotiDialog(true);
          break;
        default:
          break;
      }
    },
    [setNotiInfo, setOpenNotiDialog]
  );

  useEffect(() => {
    statusConfigs.forEach((config) => {
      const previousStatus = previousStatusesRef.current[config.statusKey];
      const currentStatus = statuses[config.statusKey];

      if (previousStatus !== currentStatus) {
        handleStatusChange(currentStatus, config.action, config.name);
        previousStatusesRef.current[config.statusKey] = currentStatus;
      }
    });
  }, [statuses, statusConfigs, handleStatusChange]);

  const resetAllStatuses = useCallback(() => {
    statusConfigs.forEach((config) => {
      dispatch(config.resetAction());
    });
  }, [dispatch, statusConfigs]);

  const handleCloseNotiDialog = useCallback(() => {
    setOpenNotiDialog(false);
    resetAllStatuses();
  }, [setOpenNotiDialog, resetAllStatuses]);

  return handleCloseNotiDialog;
};

export default useStatusChangeNotifications;
