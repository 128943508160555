import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  GlobalNameCardUserInfoFields,
  GlobalUserTypes,
} from "../constant/Global";
import {
  GlobalColor,
  GlobalFontSize,
  GlobalBorderRadius,
} from "../constant/Global";
import { BadgeIcon, EditNoteIcon, DeleteForeverIcon } from "../constant/Icons";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import NameCardUserThunks from "../../redux/thunks/nameCard/userThunks";
import { ErrorOnDelete } from "../../error";
import { errorMessages } from "../../error/errorMessages";
import EditDialog from "./EditDialog";
import { StyledAvatarSmall } from "../../styled/BaseStyles";
import { useUserType } from "../context/UserTypeContext";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 20px;
    width: 96%;
    max-width: 1300px;
  }
`;

const StyledTableCell = styled(TableCell)`
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.MuiTableCell-head {
    font-size: ${GlobalFontSize.small};
    font-weight: bold;
    color: ${GlobalColor.primary};
  }
`;

const CompanyLogo = styled.img`
  width: 36px;
  height: auto;
`;

const ActionButton = styled.button`
  background-color: ${(props) =>
    props.delete
      ? GlobalColor.red
      : props.save
      ? GlobalColor.green
      : GlobalColor.primary};
  color: ${GlobalColor.white};
  font-size: ${GlobalFontSize.small};
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  gap: 4px;
  align-items: center;
  border-radius: ${GlobalBorderRadius.small};
`;

const UserNameCardDialog = ({
  openDialog,
  targetUser,
  handleClose,
  handleDisplay,
}) => {
  const { setUserType } = useUserType();

  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const target = useSelector((state) => state.nameCard.target);
  const tableHeaders = [
    { key: "profileImg", label: "Icon" },
    { key: "companyImg", label: "Logo" },
    ...GlobalNameCardUserInfoFields,
    { key: "Name Card display", label: "Display" },
    { key: "edit", label: "Edit" },
    { key: "delete", label: "Delete" },
  ];

  const handleDeleteNameCardUser = (row) => {
    setSelectedRow(row);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteConfirmed = async () => {
    try {
      setOpenDeleteConfirmDialog(false);
      dispatch(NameCardUserThunks.deleteTarget(selectedRow.id));
    } catch (error) {
      throw new ErrorOnDelete(errorMessages.nameCardUser);
    }
  };

  const handleEdit = (row) => {
    setSelectedUser(row);
    setUserType(GlobalUserTypes.nameCardUser);
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setUserType(GlobalUserTypes.user);
    dispatch(NameCardUserThunks.getTargets(targetUser.id));
  };

  useEffect(() => {
    if (targetUser) {
      dispatch(NameCardUserThunks.getTargets(targetUser.id));
    }
  }, [targetUser, dispatch]);

  return (
    <StyledDialog open={openDialog} onClose={handleClose}>
      <DialogTitle>Manage User's Name Cards</DialogTitle>
      <DialogTitle>User Email: {targetUser.email}</DialogTitle>

      <DialogContent>
        {target.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <StyledTableCell key={header.key}>
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {target.map((row) => (
                  <TableRow key={row.id}>
                    <StyledTableCell>
                      <StyledAvatarSmall src={row.profileImgPreSignedUrl} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CompanyLogo src={row.companyImgPreSignedUrl} />
                    </StyledTableCell>
                    {GlobalNameCardUserInfoFields.map((field) => (
                      <StyledTableCell key={field.key}>
                        {row[field.key]}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell>
                      <ActionButton onClick={() => handleDisplay(row)}>
                        <BadgeIcon />
                        Display
                      </ActionButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <ActionButton onClick={() => handleEdit(row)}>
                        <EditNoteIcon />
                        Edit
                      </ActionButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <ActionButton
                        onClick={() => handleDeleteNameCardUser(row)}
                        delete="Delete"
                      >
                        <DeleteForeverIcon />
                        Delete
                      </ActionButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          "The user does not have any name cards"
        )}
      </DialogContent>
      <DeleteConfirmDialog
        openDialog={openDeleteConfirmDialog}
        onClose={handleDeleteDialogClose}
        handleDelete={handleDeleteConfirmed}
      />
      <EditDialog
        open={openEdit}
        handleClose={handleEditClose}
        editUser={selectedUser}
      />
    </StyledDialog>
  );
};

export default UserNameCardDialog;
