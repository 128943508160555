import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  IS_EMAIL_EXISTS,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILURE,
  RESET_REGISTER_STATUS,
} from "../actions/companyActions";

const initialState = {
  user: {},
  error: null,
  isEmailExists: false,
  companies: [],
  registerStatus: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        registerStatus: "success",
        error: null,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registerStatus: "fail",
        error: action.payload,
      };
    case IS_EMAIL_EXISTS:
      return {
        ...state,
        isEmailExists: action.payload,
      };
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      };
    case GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_REGISTER_STATUS:
      return {
        ...state,
        registerStatus: null,
      };
    default:
      return state;
  }
};

export default companyReducer;
