// src/utils/axiosApi.js
import axios from "axios";
import store from "../store";
import UserActions from "../redux/actions/userActions";
import { getApiUrl } from "./baseApiUrl";

const axiosApi = axios.create({
  baseURL: getApiUrl("/api/v1/"),
  headers: {
    "Content-Type": "application/json",
  },
});

const token = localStorage.getItem("accessToken");
if (token) {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(UserActions.showDialogAndLogout()); // Dispatch the action on 401 error
    }
    return Promise.reject(error);
  }
);

export default axiosApi;
