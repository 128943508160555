export const GET_TARGET_NAME_CARD_COMPANY_CONFIG_SUCCESS =
  "GET_TARGET_NAME_CARD_COMPANY_CONFIG_SUCCESS";
export const GET_TARGET_NAME_CARD_COMPANY_CONFIG_FAILURE =
  "GET_TARGET_NAME_CARD_COMPANY_CONFIG_FAILURE";
export const GET_ALL_NAME_CARD_COMPANY_CONFIGS_SUCCESS =
  "GET_ALL_NAME_CARD_COMPANY_CONFIGS_SUCCESS";
export const GET_ALL_NAME_CARD_COMPANY_CONFIGS_FAILURE =
  "GET_ALL_NAME_CARD_COMPANY_CONFIGS_FAILURE";
export const CREATE_NAME_CARD_COMPANY_CONFIG_SUCCESS =
  "CREATE_NAME_CARD_COMPANY_CONFIG_SUCCESS";
export const CREATE_NAME_CARD_COMPANY_CONFIG_FAILURE =
  "CREATE_NAME_CARD_COMPANY_CONFIG_FAILURE";
export const UPDATE_NAME_CARD_COMPANY_CONFIG_SUCCESS =
  "UPDATE_NAME_CARD_COMPANY_CONFIG_SUCCESS";
export const UPDATE_NAME_CARD_COMPANY_CONFIG_FAILURE =
  "UPDATE_NAME_CARD_COMPANY_CONFIG_FAILURE";
export const DELETE_NAME_CARD_COMPANY_CONFIG_SUCCESS =
  "DELETE_NAME_CARD_COMPANY_CONFIG_SUCCESS";
export const DELETE_NAME_CARD_COMPANY_CONFIG_FAILURE =
  "DELETE_NAME_CARD_COMPANY_CONFIG_FAILURE";
export const UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_SUCCESS =
  "UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_SUCCESS";
export const UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_FAILURE =
  "UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_FAILURE";
export const SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_SUCCESS =
  "SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_SUCCESS";
export const SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_FAILURE =
  "SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_FAILURE";
export const RESET_CREATE_COMPANY_CONFIG_STATUS =
  "RESET_CREATE_COMPANY_CONFIG_STATUS";
export const RESET_DELETE_COMPANY_CONFIG_STATUS =
  "RESET_DELETE_COMPANY_CONFIG_STATUS";
export const RESET_UPDATE_COMPANY_CONFIG_STATUS =
  "RESET_UPDATE_COMPANY_CONFIG_STATUS";
export const RESET_SET_GLOBAL_COMPANY_CONFIG_STATUS =
  "RESET_SET_GLOBAL_COMPANY_CONFIG_STATUS";

const getTargetSuccess = (data) => ({
  type: GET_TARGET_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  payload: data,
});

const getTargetFailure = (error) => ({
  type: GET_TARGET_NAME_CARD_COMPANY_CONFIG_FAILURE,
  payload: error,
});

const getAllSuccess = (data) => ({
  type: GET_ALL_NAME_CARD_COMPANY_CONFIGS_SUCCESS,
  payload: data,
});

const getAllFailure = (error) => ({
  type: GET_ALL_NAME_CARD_COMPANY_CONFIGS_FAILURE,
  payload: error,
});

const createTargetSuccess = (data) => ({
  type: CREATE_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  payload: data,
});

const createTargetFailure = (error) => ({
  type: CREATE_NAME_CARD_COMPANY_CONFIG_FAILURE,
  payload: error,
});

const deleteTargetSuccess = (data) => ({
  type: DELETE_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  payload: data,
});

const deleteTargetFailure = (error) => ({
  type: DELETE_NAME_CARD_COMPANY_CONFIG_FAILURE,
  payload: error,
});

const updateTargetSuccess = (data) => ({
  type: UPDATE_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  payload: data,
});

const updateTargetFailure = (error) => ({
  type: UPDATE_NAME_CARD_COMPANY_CONFIG_FAILURE,
  payload: error,
});

const uploadTargetImgSuccess = (data) => ({
  type: UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_SUCCESS,
  payload: data,
});

const uploadTargetImgFailure = (error) => ({
  type: UPLOAD_NAME_CARD_COMPANY_CONFIG_IMAGE_FAILURE,
  payload: error,
});

const setTargetGlobalSuccess = (data) => ({
  type: SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_SUCCESS,
  payload: data,
});

const setTargetGlobalFailure = (error) => ({
  type: SET_GLOBAL_NAME_CARD_COMPANY_CONFIG_FAILURE,
  payload: error,
});

const resetCreateCompanyConfigStatus = () => ({
  type: RESET_CREATE_COMPANY_CONFIG_STATUS,
});

const resetDeleteCompanyConfigStatus = () => ({
  type: RESET_DELETE_COMPANY_CONFIG_STATUS,
});

const resetUpdateCompanyConfigStatus = () => ({
  type: RESET_UPDATE_COMPANY_CONFIG_STATUS,
});

const resetSetGlobalCompanyConfigStatus = () => ({
  type: RESET_SET_GLOBAL_COMPANY_CONFIG_STATUS,
});

const NameCardCompanyConfigActions = {
  getTargetSuccess,
  getTargetFailure,
  getAllSuccess,
  getAllFailure,
  createTargetSuccess,
  createTargetFailure,
  updateTargetSuccess,
  updateTargetFailure,
  deleteTargetSuccess,
  deleteTargetFailure,
  uploadTargetImgSuccess,
  uploadTargetImgFailure,
  setTargetGlobalSuccess,
  setTargetGlobalFailure,
  resetCreateCompanyConfigStatus,
  resetDeleteCompanyConfigStatus,
  resetUpdateCompanyConfigStatus,
  resetSetGlobalCompanyConfigStatus,
};

export default NameCardCompanyConfigActions;
