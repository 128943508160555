import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import UserThunks from "../../redux/thunks/userThunks";
import { GlobalProfileFields } from "../constant/Global";
import { ConfirmButton, CancelButtonAlert } from "../../styled/Button";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 20px;
  }
`;

const ProfileDialog = ({ open, currentUser, handleClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(currentUser || {});

  useEffect(() => {
    setFormData(currentUser);
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    dispatch(UserThunks.updateSelf(formData.id, formData));
    handleClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>My Profile</DialogTitle>
      <DialogContent>
        {GlobalProfileFields.map((field) => (
          <TextField
            key={field.key}
            name={field.key}
            label={field.label}
            type={field.type}
            value={formData[field.key] || ""}
            onChange={handleChange}
            fullWidth
            disabled={field.isEditable ? false : true}
            margin="dense"
            contentEditable={false}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <CancelButtonAlert onClick={handleClose} color="primary">
          Cancel
        </CancelButtonAlert>
        <ConfirmButton
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </ConfirmButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default ProfileDialog;
