import axiosApi from "../../utils/axiosApi";
import UserActions from "../actions/userActions";
import { errorMessages } from "../../error/errorMessages";
import { getErrorMessage } from "../../utils/getErrorMessage";

const apiURL = "users";

export const getMySelf = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(apiURL + "/self");
    dispatch(UserActions.getTargetSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.getTargetFailure(
        errorMessage || errorMessages.user.getSelfFailed
      )
    );
  }
};

export const getAll = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(apiURL);
    dispatch(UserActions.getAllSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.getAllFailure(
        errorMessage || errorMessages.user.fetchAllFailed
      )
    );
  }
};

export const createTarget = (data) => async (dispatch) => {
  try {
    await axiosApi.post(apiURL, data);
    dispatch(UserActions.createTargetSuccess(data));
    dispatch(getAll());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.createTargetFailure(
        errorMessage || errorMessages.user.createFailed
      )
    );
  }
};

export const updateSelf = (id, data) => async (dispatch) => {
  try {
    await axiosApi.put(apiURL + `/${id}`, data);
    dispatch(UserActions.updateTargetSuccess(data));
    dispatch(getMySelf());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.updateTargetFailure(
        errorMessage || errorMessages.user.updateFailed
      )
    );
  }
};

export const updateTarget = (data) => async (dispatch) => {
  try {
    const { id } = data;
    await axiosApi.put(apiURL + `/${id}`, data);
    dispatch(UserActions.updateTargetSuccess(data));
    dispatch(getAll());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.updateTargetFailure(
        errorMessage || errorMessages.user.updateFailed
      )
    );
  }
};

export const deleteTarget = (id) => async (dispatch) => {
  try {
    await axiosApi.delete(apiURL + `/${id}`);
    dispatch(UserActions.deleteTargetSuccess(id));
    dispatch(getAll());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.deleteTargetFailure(
        errorMessage || errorMessages.user.deleteFailed
      )
    );
  }
};

export const importUsers = (file) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    await axiosApi.post(apiURL + "/import/csv", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(UserActions.importUsersSuccess());
    dispatch(getAll());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.importUsersFailure(
        errorMessage || errorMessages.user.importFailed
      )
    );
  }
};

export const downloadTemplate = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(`${apiURL}/download-template`);
    if (response.data && response.data.downloadUrl) {
      // Use the pre-signed URL to download the file
      window.open(response.data.downloadUrl);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      UserActions.downloadTemplateFailure(
        errorMessage || errorMessages.user.downloadTemplateFailed
      )
    );
  }
};

const UserThunks = {
  getMySelf,
  getAll,
  createTarget,
  updateSelf,
  updateTarget,
  deleteTarget,
  importUsers,
  downloadTemplate,
};

export default UserThunks;
